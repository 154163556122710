import { useHistory } from "react-router-dom";
import { colors, spacings } from "@/assets/themes";
import { Body16, Icon, List } from "@/components";
import { CardBase } from "@/components/Styles/Base";
import useJob from "@/modules/hooks/useJob";
import polyglot from "@/utils/polyglot";
import { useBreakpoints } from "@/modules/hooks";

const AssistantDetails = () => {
  const { switchView } = useJob();
  const history = useHistory();
  const breakpoints = useBreakpoints();
  const DETAILS_JOB = [
    {
      title: polyglot.t("jobs.see_my_job"),
      icon: "edit",
      onClick: () => {
        switchView();
      },
    },
  ];

  return (
    <div>
      <List.Header>{polyglot.t("job.service_details")}</List.Header>
      <CardBase
        flat
        border={breakpoints.get({ xs: true, md: false })}
        css={`
          /* border instead of padding to have visually better List.Item hover */
          ${breakpoints.get({
            xs: `padding-top: ${spacings.xs}; padding-bottom: ${spacings.xs};`,
            md: `border: solid ${spacings.xs} ${colors.background};`,
          })};
          overflow: hidden;
        `}
      >
        <List.Group>
          {DETAILS_JOB.map((item) => (
            <List.Item
              withGutters
              key={item.title}
              onClick={() => {
                if (item.to) {
                  history.replace(item.to);
                }
                if (item.onClick) item.onClick();
              }}
              LeftComponent={() => <Icon.Large name={item.icon} />}
            >
              <Body16>{item.title}</Body16>
            </List.Item>
          ))}
        </List.Group>
      </CardBase>
    </div>
  );
};

export default AssistantDetails;
