import { colors, spacings } from "../../assets/themes";
import { Block, Body14, Body16, Caption, Icon, List } from "../../components";
import { LIST } from "../../components/Styles/variants";

const InformationItem = ({
  RightComponent,
  icon,
  title,
  subtitle,
  value,
  AfterComponent,
  children,
  ...rest
}) => (
  <Block display="flex" alignItems="center">
    <List.Item
      size={LIST.SIZE.COMPACT}
      withGutters
      {...rest}
      RightComponent={() => (
        <>
          <>{RightComponent && RightComponent()}</>
          {value && <Body16 align="end">{value}</Body16>}
          {icon && (
            <Icon.Medium
              name={icon}
              color={colors.primary}
              css={`
                margin-left: ${spacings.sm};
              `}
            />
          )}
          {children}
        </>
      )}
    >
      {title && typeof title === "function" ? (
        title()
      ) : (
        <Body16 color={colors.muted}>{title}</Body16>
      )}
      {subtitle && typeof subtitle === "function" ? (
        subtitle()
      ) : (
        <Caption color={colors.muted}>{subtitle}</Caption>
      )}
    </List.Item>
    {AfterComponent && AfterComponent()}
  </Block>
);

export default InformationItem;
