import { useEffect, useRef, useState } from "react";
import { spacings } from "../assets/themes";
import { Block, Button, Carousel, Modal } from "../components";
import polyglot from "../utils/polyglot";
import data from "./jobberSelectionTutorial/dataJST";
import JSTStep from "./jobberSelectionTutorial/JSTStep";

const JobberSelectionTutorial = ({ isOpen = false, onClose }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [nextDisabled, setNextDisabled] = useState(false);
  const isLastStep = currentStep === data.length;
  const carouselRef = useRef(null);

  useEffect(() => {
    setNextDisabled(true);
    setTimeout(() => {
      setNextDisabled(false);
    }, 500);
  }, [currentStep]);

  const handleNext = () => {
    carouselRef.current.goNext();
  };

  const handleGoTo = (v) => {
    carouselRef.current.goTo(v - 1);
  };

  const handleCarouselChange = (_, { activePage }) => {
    setCurrentStep(activePage);
  };

  const renderNavigation = () => (
    <Block display="flex">
      {data.map((_, index) => {
        const isActive = currentStep - 1 === index;
        return (
          <button
            type="button"
            onClick={() => handleGoTo(index + 1)}
            disabled={index + 1 >= currentStep}
            key={`navigation-bubble-tutorial-${index}`}
            className={`modal-step-indicator ${isActive && "active"}`}
          >
            {index + 1}
          </button>
        );
      })}
    </Block>
  );

  const renderModalContent = () =>
    data.map((item, index) => (
      <JSTStep {...item} key={`selection-tutorial-item-${index}`} />
    ));

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Modal.Small
      isOpen={isOpen}
      onClose={handleClose}
      fullScreenOnMobile
      shouldCloseOnOverlayClick={false}
    >
      <Modal.Item.Header onClose={handleClose} />
      <Block flex="1">
        <Carousel
          slidesToShow={1}
          initialPage={1}
          hideNavigation
          draggable={false}
          ref={carouselRef}
          onChange={handleCarouselChange}
        >
          {renderModalContent()}
        </Carousel>
      </Block>
      <Block position="sticky" bottom={spacings.m}>
        <Block
          display="flex"
          justifyContent="center"
          width="100%"
          marginBottom={spacings.m}
          marginTop="auto"
        >
          <Button.Large
            disabled={nextDisabled}
            onClick={() => (isLastStep ? handleClose() : handleNext())}
          >
            {isLastStep
              ? `${polyglot.t("common.alright")} !`
              : polyglot.t("common.navigation.next")}
          </Button.Large>
        </Block>
        <Block display="flex" justifyContent="center" marginY={spacings.m}>
          {renderNavigation()}
        </Block>
      </Block>
    </Modal.Small>
  );
};

export default JobberSelectionTutorial;
