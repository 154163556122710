import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import BrandSpinner from "../assets/lottie/brand-spinner.lottie";
import { colors, sizes } from "../assets/themes";
import Icon from "./Icon";

const Spinner = ({
  className,
  size,
  brand,
  color = colors.primary,
  children,
}) => (
  <div className={className}>
    {!brand ? (
      <Icon.Medium size={size} name="spinner" spin color={color} />
    ) : (
      <DotLottieReact
        autoplay
        loop
        src={BrandSpinner}
        style={{ width: size, height: size }}
      />
    )}
    {children && (
      <span className="mt-3 text-center text-muted">{children}</span>
    )}
  </div>
);

const Large = ({ className, ...rest }) => (
  <Spinner size={sizes.size48} brand {...rest} />
);
const Medium = ({ className, ...rest }) => (
  <Spinner size={sizes.size32} brand {...rest} />
);
const Small = ({ className, ...rest }) => (
  <Spinner size={sizes.size16} {...rest} />
);
export default { Large, Small, Medium };
