import PaymentMethodsUpdatedA from "./PaymentMethodsA";
import PaymentMethodsUpdated from "./PaymentMethods";
import PayButtonEnhancer from "./PayButtonEnhancer";
import NewCreditCardModal from "./credit-card-fields/NewCreditCardModal";
import Methods from "./Methods";

PaymentMethodsUpdated.A = PaymentMethodsUpdatedA;
PaymentMethodsUpdated.New = NewCreditCardModal;
PaymentMethodsUpdated.Methods = Methods;
PaymentMethodsUpdated.PayButtonEnhancer = PayButtonEnhancer;

export default PaymentMethodsUpdated;
