import { useFormikContext } from "formik";
import { useEffect } from "react";
import CreditCard from "./CreditCard";
import { VISA_MASTERCARD } from "./constants";
import { browserInfo } from "./utils";

const PaymentMethodsUpdated = ({
  methods: _methods,
  onChange,
  needCCCheck,
  disabled,
  withGutters,
  cardRegistrationDataParams,
}) => {
  const { validateForm, setValues, setFieldValue } = useFormikContext();

  let methods = _methods.includes(VISA_MASTERCARD)
    ? _methods
    : [VISA_MASTERCARD, ..._methods];
  methods = methods.map((m) => m.toLowerCase());

  useEffect(() => {
    setFieldValue("browserInfo", browserInfo);
  }, []);

  const handleChange = ({ payment_method, token, card_number, card_type }) => {
    let arr;
    if (card_number) {
      arr = { ...arr, card_number };
    }
    if (card_type) {
      arr = { ...arr, card_type };
    }
    if (token) {
      arr = { ...arr, token };
    }
    if (payment_method) {
      arr = { ...arr, payment_method };
    }
    setValues((s) => ({ ...s, ...arr }), false);
    setTimeout(() => {
      validateForm();
    }, 0);
    if (onChange) onChange(arr);
  };

  return (
    <>
      <CreditCard
        methods={methods}
        needCCCheck={needCCCheck}
        onChange={handleChange}
        disabled={disabled}
        withGutters={withGutters}
        cardRegistrationDataParams={cardRegistrationDataParams}
      />
    </>
  );
};

export default PaymentMethodsUpdated;
