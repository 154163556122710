import { useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Block, Button, MobileHeader } from "@/components";
import { BUTTON, MOBILE_HEADER } from "@/components/Styles/variants";
import { useBreakpoints, useIntersectionObserver } from "@/modules/hooks";
import useJob from "@/modules/hooks/useJob";
import polyglot from "@/utils/polyglot";
import JobHelpBox from "./JobHelpBox";

const JobMobileHeader = () => {
  const { isPoster, data } = useJob();
  const breakpoint = useBreakpoints();
  const history = useHistory();
  const [helpModalIsVisible, setHelpModalIsVisible] = useState();
  const [isScrolled, setIsScrolled] = useState(false);

  const anchorRef = useRef();
  useIntersectionObserver({
    target: anchorRef,
    onObserver: (entry) => {
      setIsScrolled(!entry.isIntersecting);
    },
  });

  if (isPoster && breakpoint.get({ xs: true, md: false }))
    return (
      <>
        <div ref={anchorRef} />
        <Block
          position="fixed"
          top="var(--nav-height)"
          left="0"
          right="0"
          zIndex="9"
        >
          <MobileHeader
            kind={!isScrolled ? MOBILE_HEADER.KIND.FLOATING : undefined}
            onBack={() => history.goBack()}
            RightComponent={() => (
              <>
                <Button.Small
                  kind={BUTTON.KIND.SECONDARY}
                  shape={BUTTON.SHAPE.PILL}
                  onClick={() => setHelpModalIsVisible(true)}
                >
                  {polyglot.t("common.help")}
                </Button.Small>
                <JobHelpBox.Modal
                  isOpen={helpModalIsVisible}
                  onClose={() => setHelpModalIsVisible(false)}
                />
              </>
            )}
          />
        </Block>
      </>
    );

  return null;
};

export default JobMobileHeader;
