import { useState } from "react";
import { colors, radius, sizes, spacings } from "../../assets/themes";
import { getValidationOfferUrl } from "../../pages/Job/utils";
import Block from "../Block";
import Button from "../Button";
import Icon from "../Icon";
import List from "../List";
import Rating from "../Rating";
import Shape from "../Shape";
import { LIST, LIST_HEADER, SHAPE, TAG } from "../Styles/variants";
import Tag from "../Tag";
import { Body14, Body16 } from "../Text";
import EditRatingModal from "./EditRatingModal";
import polyglot from "@/utils/polyglot";
import JobberProfileGallery from "@/pages/JobberProfile/JobberProfileGallery";
import RateItem from "@/pages/JobberProfile/RateItem";

const OfferJobberRating = ({
  id,
  rate,
  rateable,
  openInModal,
  jobber,
  renderHeader,
  onSuccess,
  backgroundColor = colors.backgroundLight,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const rightComponent = rate?.editable
    ? () => (
        <Button.Link
          onClick={() => setModalIsOpen(true)}
          css={`
            padding-left: 0;
            padding-right: 0;
          `}
        >
          {polyglot.t("common.edit_my_rating")}
        </Button.Link>
      )
    : null;

  const headerProps = {
    kind: LIST_HEADER.KIND.SUBTITLE,
    RightComponent: rightComponent,
    children: polyglot.t("common.rating"),
  };

  const defaultHeader = (
    <Block display="flex" alignItems="baseline" marginBottom={spacings.s}>
      <Body14
        color={colors.muted}
        css={`
          flex: 1;
        `}
      >
        {headerProps.children}
      </Body14>
      {headerProps.RightComponent && headerProps.RightComponent()}
    </Block>
  );

  return (
    <>
      <EditRatingModal
        isOpen={modalIsOpen}
        isNew={!rate}
        onClose={() => setModalIsOpen(false)}
        onSuccess={onSuccess}
        rate={rate}
        jobber={jobber}
        id={id}
      />
      {/* Show header only if a rate is avalailable (for edit) or if in transaction details */}
      {(rate || renderHeader) && (
        <>{renderHeader ? renderHeader(headerProps) : defaultHeader}</>
      )}
      {rate && (
        <Block
          padding={spacings.m}
          borderRadius={radius.ml}
          backgroundColor={backgroundColor}
        >
          <RateItem {...rate} smallPhotos />
        </Block>
      )}
      {rateable && (
        <List.Item
          divider={false}
          withGutters
          shape={LIST.SHAPE.ROUND}
          css={`
            background-color: ${backgroundColor}!important;
          `}
          LeftComponent={() => (
            <Shape.Small
              shape={SHAPE.SHAPE.CIRCLE}
              backgroundColor={colors.warning}
              color={colors.warningDark}
            >
              <Icon.Medium name="star-solid" />
            </Shape.Small>
          )}
          chevron
          href={openInModal ? undefined : getValidationOfferUrl(id)}
          onClick={openInModal ? () => setModalIsOpen(true) : undefined}
        >
          <Body16 strong>
            {polyglot.t("job.add_rating_to_first_name", {
              first_name: jobber.first_name,
            })}
          </Body16>
          <Body14>{polyglot.t("job.add_rating_description")}</Body14>
        </List.Item>
      )}
    </>
  );
};
export default OfferJobberRating;
