import { FastField } from "formik";
import { Block, FormControl, Info, Input } from "../../../../components";
import polyglot from "../../../../utils/polyglot";

const CVXField = () => (
  <FastField name="cardCvx">
    {({ field }) => (
      <FormControl
        css={`
          margin-bottom: 0;
        `}
        label={() => (
          <Block display="flex" alignItems="center">
            <span
              css={`
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              `}
            >
              {polyglot.t("payment_methods.security_code")}
            </span>
            <Info>
              {polyglot.t("payment_methods.security_code_description")}
            </Info>
          </Block>
        )}
      >
        <Input
          inputMode="numeric"
          placeholder="123"
          autoComplete="cc-csc"
          {...field}
        />
      </FormControl>
    )}
  </FastField>
);

export default CVXField;
