import { colors, sizes, spacings } from "../../../assets/themes";
import { Badge, Block, Body14, Icon, Tag } from "../../../components";
import { BADGE, PROGRESS, TAG } from "../../../components/Styles/variants";
import { getReferralMenuClickEvent } from "../../../utils/analytics-events";
import polyglot from "../../../utils/polyglot";
import VipBadge from "../../../assets/images/svg-icons/Badges/Vip.svg";
import {
  Billings,
  Business,
  Cesu,
  EditAccount,
  ManageNotifications,
  Wallet,
} from "../account/index";
import Referral from "../Referral";
import Tickets from "../Tickets";
import {
  ACCOUNT_BILLING_PATH,
  ACCOUNT_CESU_PATH,
  ACCOUNT_COMPANY_PATH,
  ACCOUNT_INFORMATIONS_PATH,
  ACCOUNT_NOTIFICATIONS_PATH,
  ACCOUNT_WALLET_PATH,
  REFERRAL_PATH,
  ACCOUNT_TAXES_PATH,
  TICKETS_PATH,
  ACCOUNT_BLOCKED_JOBBERS_PATH,
  ACCOUNT_PAYMENT_METHODS_PATH,
} from "./routes";
import Progress from "../../../components/progress";
import { formatDate } from "../../../utils";
import TaxesCredit from "../TaxesCredit";
import BlockedJobbers from "../account/BlockedJobbers";
import ManagePaymentMethods from "../account/ManagePaymentMethods";

export const GET_ACCOUNT_MENU = ({ data, user, public_pages }) => [
  {
    title: polyglot.t("account.manage_my_account"),
    routes: [
      {
        path: ACCOUNT_INFORMATIONS_PATH,
        title: polyglot.t("routes.personal_informations"),
        subtitle: polyglot.t("routes.personal_informations_description"),
        LeftComponent: () => <Icon.XLarge name="user" color={colors.primary} />,
        View: EditAccount,
      },
      {
        path: ACCOUNT_WALLET_PATH,
        title: polyglot.t("routes.wallet"),
        // No wallet_amount in browser title because the data used is in the /account query
        renderTitle: () =>
          `${polyglot.t("routes.wallet")} (${polyglot.toSmartCurrency(
            user?.total_wallet_amount
          )})`,
        subtitle: polyglot.t("routes.wallet_description"),
        LeftComponent: () => (
          <Icon.XLarge name="wallet" color={colors.primary} />
        ),
        View: Wallet,
      },
      ...(polyglot.country === "fr"
        ? {
            path: ACCOUNT_CESU_PATH,
            title: polyglot.t("routes.cesu"),
            subtitle: polyglot.t("routes.cesu_description"),
            LeftComponent: () => (
              <Icon.XLarge name="ticket" color={colors.primary} />
            ),
            View: Cesu,
          }
        : []),
      {
        path: ACCOUNT_PAYMENT_METHODS_PATH,
        title: polyglot.t("routes.payment_methods"),
        subtitle: polyglot.t("routes.payment_methods_description"),
        LeftComponent: () => (
          <Icon.XLarge name="credit-card" color={colors.primary} />
        ),
        View: ManagePaymentMethods,
      },
      {
        path: ACCOUNT_NOTIFICATIONS_PATH,
        title: polyglot.t("routes.manage_notifications"),
        subtitle: polyglot.t("routes.manage_notifications_description"),
        LeftComponent: () => <Icon.XLarge name="bell" color={colors.primary} />,
        View: ManageNotifications,
      },
      {
        path: ACCOUNT_BILLING_PATH,
        title: polyglot.t("routes.billings"),
        subtitle: polyglot.t("routes.billings_description"),
        LeftComponent: () => (
          <Icon.XLarge name="document" color={colors.primary} />
        ),
        View: Billings,
      },
      ...(user?.is_a_company
        ? {
            path: ACCOUNT_COMPANY_PATH,
            title: polyglot.t("routes.company"),
            subtitle: polyglot.t("routes.company_description"),
            LeftComponent: () => (
              <Icon.XLarge name="building" color={colors.primary} />
            ),
            View: Business,
          }
        : []),
    ],
  },
  {
    title: polyglot.t("account.products"),
    routes: [
      ...(polyglot.country === "fr"
        ? {
            path: ACCOUNT_TAXES_PATH,
            title: polyglot.t("routes.taxes"),
            subtitle: polyglot.t("routes.taxes_description"),
            LeftComponent: () => (
              <Icon.XLarge name="document" color={colors.primary} />
            ),
            View: TaxesCredit,
          }
        : []),
      {
        title: polyglot.t("routes.referral"),
        path: REFERRAL_PATH,
        View: Referral,
        event: getReferralMenuClickEvent(),
        subtitle: polyglot.t("routes.referral_description"),
        LeftComponent: () => (
          <Icon.XLarge name="user-plus" color={colors.primary} />
        ),
      },
      {
        href: public_pages.vip,
        title: polyglot.t("routes.vip_status"),
        subtitle: polyglot.t("routes.vip_status_description"),
        renderSubtitle: () => (
          <Block>
            {polyglot.t("routes.vip_status_description")}
            <Block marginTop={spacings.xs}>
              {user?.vip_status?.vip_active ? (
                <Body14>
                  {polyglot.t("vip.active_until_date", {
                    date: formatDate(
                      user?.vip_status?.calendar_end_date,
                      "DD MMM YYYY"
                    ),
                  })}
                </Body14>
              ) : (
                <Progress.Bar
                  css={`
                    &:before {
                      background-color: ${colors.purple};
                    }
                  `}
                  LeftComponent={() => (
                    <Body14 strong color={colors.purple}>
                      {user?.vip_status?.progress} %
                    </Body14>
                  )}
                  shape={PROGRESS.SHAPE.ROUND}
                  value={user?.vip_status?.progress}
                />
              )}
            </Block>
          </Block>
        ),
        LeftComponent: () => (
          <Block
            // same size as Icon XLARGE -> Layout alignement
            width={sizes.size20}
            height={sizes.size20}
            display="flex"
            alignItems="center"
            // justifyContent="center"
          >
            <img src={VipBadge} alt={polyglot.t("routes.vip_status")} />
          </Block>
        ),
      },
      {
        href: public_pages?.gift_card,
        title: polyglot.t("routes.gift_card"),
        subtitle: polyglot.t("routes.gift_card_description"),
        LeftComponent: () => <Icon.XLarge name="gift" color={colors.primary} />,
      },
    ],
  },
  {
    title: polyglot.t("account.usefull_informations"),
    routes: [
      {
        href: public_pages?.faq,
        title: polyglot.t("routes.help_center"),
        subtitle: polyglot.t("routes.help_center_description"),
        LeftComponent: () => (
          <Icon.XLarge name="support" color={colors.primary} />
        ),
      },
      {
        path: TICKETS_PATH,
        title: polyglot.t("routes.tickets"),
        subtitle: polyglot.t("routes.tickets_description"),
        LeftComponent: () => (
          <Badge
            count={data?.total_unread_ticket_messages}
            position={BADGE.POSITION.TOP_RIGHT}
          >
            <Icon.XLarge name="envelope" color={colors.primary} />
          </Badge>
        ),
        View: Tickets,
      },
      ...(polyglot.country === "fr"
        ? {
            href: public_pages?.tax_credit,
            title: polyglot.t("routes.tax_credit"),
            subtitle: polyglot.t("routes.tax_credit_description"),
            LeftComponent: () => (
              <Icon.XLarge name="percent" color={colors.primary} />
            ),
          }
        : []),
      {
        href: public_pages?.insurance,
        title: polyglot.t("routes.insurance_alt"),
        subtitle: polyglot.t("routes.insurance_description_alt"),
        LeftComponent: () => (
          <Icon.XLarge name="shield-half" color={colors.primary} />
        ),
      },
      {
        path: ACCOUNT_BLOCKED_JOBBERS_PATH,
        title: polyglot.t("routes.blocked_jobbers"),
        subtitle: polyglot.t("routes.blocked_jobbers_description"),
        View: BlockedJobbers,
        LeftComponent: () => (
          <Icon.XLarge name="user-times" color={colors.primary} />
        ),
      },
      {
        href: public_pages?.trust_security,
        title: polyglot.t("routes.trust_and_security"),
        subtitle: polyglot.t("routes.trust_and_security_description"),
        LeftComponent: () => (
          <Icon.XLarge name="shield-check" color={colors.primary} />
        ),
      },
      {
        href: public_pages?.jobber_app,
        title: polyglot.t("routes.become_jobber"),
        subtitle: polyglot.t("routes.become_jobber_description"),
        LeftComponent: () => <Icon.XLarge name="work" color={colors.primary} />,
      },
    ],
  },
];
