import React from "react";

const Group = ({ children }) =>
  React.Children.toArray(children)
    .filter(React.isValidElement)
    .map((child, index, array) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          ...child.props,
          divider: index < array.length - 1,
        });
      }
      return null;
    });

export default Group;
