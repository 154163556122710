import { Field, useFormikContext } from "formik";
import { useEffect } from "react";
import polyglot from "@/utils/polyglot";
import { colors, spacings } from "../../../../assets/themes";
import {
  Alert,
  Block,
  Caption,
  Checkbox,
  Col,
  Modal,
  Row,
} from "../../../../components";
import { ALERT } from "../../../../components/Styles/variants";
import Methods from "../Methods";
import CreditCardNumbersField from "./CreditCardNumbersField";
import CVXField from "./CVXField";
import ExpirationDateField from "./ExpirationDateField";
import { getPaymentMethodConfig } from "../utils";

const CustomWrapper = ({ withGutters, children }) =>
  withGutters ? (
    <Modal.Item.Wrapper>{children}</Modal.Item.Wrapper>
  ) : (
    <div>{children}</div>
  );

const CreditCardFields = ({
  children,
  withGutters,
  methods,
  mangopay_cards,
}) => {
  const { status } = useFormikContext();
  const showDefaultCheckbox = mangopay_cards?.length >= 1;

  return (
    <>
      <CustomWrapper withGutters={withGutters}>
        {status && (
          <Block marginBottom={spacings.s}>
            <Alert.Low title={status} kind={ALERT.KIND.DANGER} />
          </Block>
        )}
        <CreditCardNumbersField
          method={methods.length === 1 ? methods?.[0] : null}
        />
        <Row gutter={spacings.s}>
          <Col size={6}>
            <ExpirationDateField />
          </Col>
          <Col size={6}>
            <CVXField />
          </Col>
        </Row>
        {(showDefaultCheckbox || methods?.length > 1) && (
          <Block spaceY={spacings.m} marginTop={spacings.m}>
            {showDefaultCheckbox && (
              <Field name="current" type="checkbox">
                {({ field, form }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={(e) =>
                      form.setFieldValue("current", e.target.checked)
                    }
                  >
                    {polyglot.t("payment_method.set_as_default")}
                  </Checkbox>
                )}
              </Field>
            )}
            {methods?.length > 1 && (
              <Block>
                <Caption strong color={colors.muted}>
                  {polyglot.t("payment_methods.most_popular_payment_methods")}
                </Caption>
                <Block
                  display="flex"
                  flexWrap="wrap"
                  gap={spacings.s}
                  marginTop={spacings.xs}
                >
                  <Methods
                    methods={methods.filter(
                      (method) => !getPaymentMethodConfig(method).external
                    )}
                  />
                </Block>
              </Block>
            )}
          </Block>
        )}
      </CustomWrapper>
      {children}
    </>
  );
};

export default CreditCardFields;
