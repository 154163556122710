import styled, { css } from "styled-components";
import VerifiedBadge from "../../assets/images/svg-icons/Badges/Verified.svg";
import {
  borderWidth,
  colors,
  radius,
  sizes,
  spacings,
} from "../../assets/themes";
import Block from "../Block";
import Icon from "../Icon";
import { cssVarToNumber } from "../Styles/Helper";

const INITIAL_FONT_SIZE_RATIO = 1.8;

const getColorByName = (name) => {
  const INDEX_OFFSET = 97; // 'a' in ASCII
  const availableColors = [
    colors.warning600,
    colors.warning500,
    colors.turquoise600,
    colors.turquoise500,
    colors.success600,
    colors.success500,
    colors.purple600,
    colors.purple500,
    colors.primary600,
    colors.primary500,
    colors.pink600,
    colors.pink500,
    colors.orangered600,
    colors.orangered500,
    colors.orange600,
    colors.orange500,
    colors.danger600,
    colors.danger500,
    colors.brown600,
    colors.brown500,
  ];
  const firstLetter = name.toLowerCase().charCodeAt(0) - INDEX_OFFSET;
  const n2lastLetter =
    name.toLowerCase().charCodeAt(name.length - 2) - INDEX_OFFSET;
  const combinedIndex = (firstLetter + n2lastLetter) % availableColors.length;
  return availableColors[combinedIndex];
};

const getSize = ({ width, height, size }) => {
  if (width || height) {
    return css`
      width: ${width};
      min-width: ${width};
      max-width: ${width};
      height: ${height || width};
      min-height: ${height || width};
      max-height: ${height || width};
    `;
  }
  return css`
    width: ${size};
    min-width: ${size};
    max-width: ${size};
    height: ${size};
    min-height: ${size};
    max-height: ${size};
  `;
};

const StyledInitial = styled.div`
  ${"" /* //Prevent <a href/> text underline with absolute position */}
  position: absolute;
  font-weight: var(--font-weight-medium);
  font-size: ${({ size }) => cssVarToNumber(size) / INITIAL_FONT_SIZE_RATIO}px;
  color: ${colors.onColor};
  user-select: none;
  ${"" /* Safari rdy */}
  -webkit-user-select: none;
  opacity: 1;
`;

const StyledWrapper = styled.div`
  ${({ isVerified, isTopJobber }) =>
    (isVerified || isTopJobber) &&
    css`
      position: relative;
    `};
  ${({ isTopJobber, size }) =>
    isTopJobber &&
    css`
      > img,
      > svg {
        border-radius: 999px;
        border: solid
          ${cssVarToNumber(size) >= cssVarToNumber(sizes.size64)
            ? borderWidth.l
            : "3px"}
          ${colors.purple};
      }
    `}
  ${({ width, height, size, name, src }) => css`
    border-radius: ${radius.circle};
    background-color: ${!src && name ? getColorByName(name) : colors.muted};
    img {
      border-radius: ${radius.circle};
    }
    ${getSize({ width, height, size })};
    display: flex;
  `}
`;

const getTopJobberBadgeSize = (baseSize) =>
  Math.min(cssVarToNumber(baseSize) * 0.4, 36);

const StyledTopJobberBadge = styled.div`
  ${({ size }) => {
    // calc scaled spacing based on 4px spacing and 96px size
    // css calc didnt work here (?)
    const placement =
      (cssVarToNumber(size) * cssVarToNumber(spacings.xxs)) /
      cssVarToNumber(sizes.size96);

    return css`
      position: absolute;
      top: ${placement * -1}px;
      right: ${placement * -1}px;
      border-radius: 50%;
      background: ${colors.purple};
      width: ${getTopJobberBadgeSize(size)}px;
      height: ${getTopJobberBadgeSize(size)}px;
      display: flex;
      align-items: center;
      justify-content: center;
      > * {
        display: block;
      }
    `;
  }}
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledBadge = styled.img`
  ${({ size }) => css`
    position: absolute;
    top: 0%;
    right: -1%;
    width: calc(${size} * 0.3);
    max-width: ${sizes.size24};
    height: auto;
  `}
`;

const Avatar = ({
  src,
  alt,
  width,
  height,
  size = sizes.size48,
  isVerified,
  isTopJobber,
  children,
  name,
  ...rest
}) => {
  const renderContent = () => {
    if (src) {
      return <StyledImage as="img" src={src} alt={alt} />;
    }
    if (name) {
      return (
        <Block
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <StyledInitial size={size}>{name[0]}</StyledInitial>
        </Block>
      );
    }
    return (
      <StyledImage
        as="svg"
        aria-hidden="true"
        aria-label="Photo de profil"
        fill="none"
        viewBox="0 0 56 56"
      >
        <rect fill="#c4c9ce" height="56" rx="28" width="56" />
        <path
          d="M47.772 44.885A25.94 25.94 0 0 1 28 54a25.94 25.94 0 0 1-19.772-9.115c1.283-1.663 2.893-3.02 4.794-4.037 2.662-1.423 5.662-2.182 10.783-2.182h8.389c5.122 0 8.122.758 10.783 2.182a14.84 14.84 0 0 1 4.794 4.036zM28.001 8.334c7.732 0 14 6.268 14 14s-6.268 14-14 14-14-6.268-14-14 6.268-14 14-14z"
          fill="#fff"
        />
      </StyledImage>
    );
  };

  return (
    <StyledWrapper
      size={size}
      width={width}
      height={height}
      isVerified={isVerified}
      isTopJobber={isTopJobber}
      name={name}
      src={src}
      {...rest}
    >
      {isVerified && !isTopJobber && (
        <StyledBadge src={VerifiedBadge} size={size} />
      )}
      {isTopJobber && (
        <StyledTopJobberBadge size={size}>
          <Icon.Large
            name="love-solid"
            color={colors.onColor}
            size={`${Math.min(cssVarToNumber(size) * 0.25, 20)}px`}
          />
        </StyledTopJobberBadge>
      )}
      {renderContent()}
    </StyledWrapper>
  );
};

export default Avatar;
