import { useHistory, useParams } from "react-router-dom";
import { Alert, Modal } from "../../components";
import { ALERT } from "../../components/Styles/variants";
import useJob from "../../modules/hooks/useJob";
import { useCreateDateTimeRequest } from "../../modules/routes/dashboard-routes";
import { useUpdateDateTime } from "../../modules/routes/job-routes";
import polyglot from "../../utils/polyglot";
import EditDateTime from "../EditDateTime";
import { EDIT_INFORMATIONS_PATH, getSelectedOffers } from "../Job/utils";
import EditView from "./EditView";

const EditJobModal = ({ isOpen = true, onClose, canBack = true }) => {
  const { data } = useJob();
  const history = useHistory();
  const params = useParams();

  const hasSelectedOffers = () =>
    getSelectedOffers(data.accepted_offers).length > 0;

  const handleBack = () => {
    history.replace(EDIT_INFORMATIONS_PATH);
  };

  const updateDateTime = useUpdateDateTime({
    id: data.id,
    onSuccess: () => {
      handleBack();
    },
  });
  const createDateTime = useCreateDateTimeRequest({
    id: data.id,
    redirect: true,
  });

  return (
    <Modal.Small isOpen={isOpen} onClose={onClose} fullScreenOnMobile>
      {params.field === "datetime" ? (
        <EditDateTime
          isOpen
          inContent
          discussable_title={data.title}
          discussable_id={data.id}
          isBooked={hasSelectedOffers()}
          query={hasSelectedOffers() ? createDateTime : updateDateTime}
          onBack={handleBack}
          canBack={canBack}
          onClose={onClose}
        >
          {!hasSelectedOffers() && (
            <Alert.Low kind={ALERT.KIND.WARNING}>
              {polyglot.t("job.edit_datetime_short_alert")}
            </Alert.Low>
          )}
        </EditDateTime>
      ) : (
        <EditView onClose={onClose} parentHistory={history} />
      )}
    </Modal.Small>
  );
};

export default EditJobModal;
