import moment from "moment";
import { useEffect, useState } from "react";
import amex from "@/assets/images/credit-cards/amex.jpg";
import maestro from "@/assets/images/credit-cards/maestro.jpg";
import mastercard from "@/assets/images/credit-cards/mastercard.jpg";
import visa from "@/assets/images/credit-cards/visa.jpg";
import { colors, spacings } from "@/assets/themes";
import {
  Block,
  Body14,
  Body16,
  Breadcrumb,
  Button,
  Caption,
  Display3,
  H2,
  Icon,
  Link,
  List,
  NoResult,
  Tag,
} from "@/components";
import { BUTTON, TAG } from "@/components/Styles/variants";
import { useBreakpoints } from "@/modules/hooks";
import { useGetPaymentMethods } from "@/modules/routes/dashboard-routes";
import {
  useAddPaymentMethod,
  useRemovePaymentMethod,
  useUpdateCurrentPaymentMethod,
} from "@/modules/routes/payment-routes";
import PaymentMethodsUpdated from "@/pages/common/payment-methods";
import { VISA_MASTERCARD } from "@/pages/common/payment-methods/constants";
import { getPaymentMethodBadge } from "@/pages/common/payment-methods/utils";
import { DASHBOARD_SMALL_CONTAINER } from "@/utils";
import polyglot from "@/utils/polyglot";
import PageTitle from "../PageTitle";
import { ACCOUNT_PATH } from "../config/routes";
import ManagePaymentMethodsSkeleton from "../skeletons/ManagePaymentMethodsSkeleton";
import PaymentMethodDetailsModal from "./manage-payment-methods/PaymentMethodDetailsModal";
import PaymentMethodItem from "./manage-payment-methods/PaymentMethodItem";

const ManagePaymentMethods = ({ config }) => {
  const { data, isLoading, refetch, isSuccess } = useGetPaymentMethods();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const addPaymentMethod = useAddPaymentMethod();
  const removePaymentMethod = useRemovePaymentMethod();
  const updateCurrentPaymentMethod = useUpdateCurrentPaymentMethod();
  const breakpoint = useBreakpoints();

  useEffect(async () => {
    refetch();
    removePaymentMethod.reset();
    updateCurrentPaymentMethod.reset();
  }, [removePaymentMethod.isSuccess, updateCurrentPaymentMethod.isSuccess]);

  useEffect(() => {
    if (!addPaymentMethod.data?.data?.data?.secure_url && isSuccess) {
      setModalIsOpen(false);
      refetch();
      addPaymentMethod.reset();
    }
  }, [addPaymentMethod.isSuccess, isSuccess]);

  useEffect(() => {
    if (
      isSuccess &&
      (updateCurrentPaymentMethod.isSuccess || removePaymentMethod.isSuccess)
    ) {
      setSelectedCardId(null);
    }
  }, [
    removePaymentMethod.isSuccess,
    updateCurrentPaymentMethod.isSuccess,
    isSuccess,
  ]);

  const getDefaultCard = () =>
    data?.mangopay_cards.find((card) => card.current) ?? {};

  const { kind } = getDefaultCard();

  const getCardBackground = () => {
    if (kind === "visa") {
      return visa;
    }
    if (kind === "mastercard") {
      return mastercard;
    }
    if (kind === "maestro") {
      return maestro;
    }
    if (kind === "amex") {
      return amex;
    }
    return visa;
  };

  const getCardSplitedNumber = (cardNumbers) =>
    cardNumbers?.replaceAll("X", "•").match(/.{1,4}/g) || [];

  return (
    <Block maxWidth={DASHBOARD_SMALL_CONTAINER} width="100%">
      <Block marginBottom={spacings.ml}>
        <Breadcrumb>
          <Link to={ACCOUNT_PATH}>{polyglot.t("routes.account")}</Link>
          <Block as="span">{config.title}</Block>
        </Breadcrumb>
      </Block>
      <PageTitle>{config.title}</PageTitle>

      {!isLoading ? (
        <>
          {data?.mangopay_cards.length > 0 ? (
            <>
              {getDefaultCard()?.alias && (
                <Block marginTop={spacings.ml}>
                  <Block display="flex">
                    <Block
                      css="aspect-ratio: 1.7;"
                      maxWidth="100%"
                      width="100%"
                      backgroundImage={`url(${getCardBackground()})`}
                      backgroundSize="cover"
                      position="relative"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius={spacings.sm}
                    >
                      <Block
                        padding={spacings.sm}
                        top="0"
                        left="0"
                        position="absolute"
                      >
                        <img src={getPaymentMethodBadge(kind)} alt={kind} />
                      </Block>
                      <Display3
                        as={breakpoint.get({ xs: H2, sm: Display3 })}
                        color={colors.white}
                        css={`
                          width: 100%;
                        `}
                      >
                        <Block
                          display="flex"
                          justifyContent={breakpoint.get({
                            xs: "flex-start",
                            sm: "space-between",
                          })}
                          gap={spacings.m}
                          paddingX={breakpoint.get({
                            xs: spacings.m,
                            sm: spacings.l,
                          })}
                          paddingY={spacings.m}
                          color={colors.white}
                          css={`
                            font-feature-settings: var(--font-feature-settings),
                              "case" 1;
                          `}
                        >
                          {getCardSplitedNumber(getDefaultCard().alias).map(
                            (part, index) => (
                              <span key={index}>{part}</span>
                            )
                          )}
                        </Block>
                      </Display3>
                      <Block
                        position="absolute"
                        bottom={{ xs: spacings.ml, md: spacings.l }}
                        left={{
                          xs: spacings.m,
                          sm: spacings.l,
                        }}
                        display="flex"
                        gap={spacings.l}
                        color={colors.white}
                        alignItems="flex-start"
                      >
                        <div>
                          <Body16
                            as={breakpoint.get({ xs: Caption, sm: Body16 })}
                          >
                            {polyglot.t("payment_methods.expiration_date")}
                          </Body16>
                          <Body16
                            as={breakpoint.get({ xs: Body14, sm: Body16 })}
                            strong
                          >
                            {moment(getDefaultCard().expiration_date).format(
                              "MM/YY"
                            )}
                          </Body16>
                        </div>
                        <div>
                          <Body16
                            as={breakpoint.get({ xs: Caption, sm: Body16 })}
                          >
                            {polyglot.t("payment_methods.security_code_short")}
                          </Body16>
                          <Body16
                            as={breakpoint.get({ xs: Body14, sm: Body16 })}
                            strong
                          >
                            •••
                          </Body16>
                        </div>
                      </Block>
                      <Block
                        position="absolute"
                        right="0"
                        bottom="0"
                        padding={spacings.sm}
                      >
                        <Tag.Medium kind={TAG.KIND.WHITE}>
                          {polyglot.t("common.default")}
                        </Tag.Medium>
                      </Block>
                    </Block>
                  </Block>
                </Block>
              )}

              <List.Header>
                {polyglot.t("manage_payment_method.payment_cards")}
              </List.Header>
              <Block spaceY={spacings.s}>
                <>
                  {data.mangopay_cards.map((card) => (
                    <div key={card.id}>
                      <PaymentMethodItem
                        card={card}
                        onClick={() => setSelectedCardId(card.id)}
                      />
                      <PaymentMethodDetailsModal
                        card={card}
                        isOpen={selectedCardId === card.id}
                        onRemove={() =>
                          removePaymentMethod.mutate({ id: card.id })
                        }
                        onDefault={() =>
                          updateCurrentPaymentMethod.mutate({ id: card.id })
                        }
                        isDefaultLoading={
                          updateCurrentPaymentMethod.isLoading ||
                          updateCurrentPaymentMethod.isSuccess
                        }
                        isRemoveLoading={
                          removePaymentMethod.isLoading ||
                          removePaymentMethod.isSuccess
                        }
                        onClose={() => setSelectedCardId(null)}
                      />
                    </div>
                  ))}
                </>
              </Block>
              <Block marginTop={spacings.m}>
                <Button.Link
                  LeftComponent={() => <Icon.Medium name="plus" />}
                  onClick={() => setModalIsOpen(true)}
                  css={`
                    padding-left: 0px;
                    padding-right: 0px;
                  `}
                >
                  {polyglot.t("payment_method.add_card")}
                </Button.Link>
              </Block>
            </>
          ) : (
            <NoResult
              title={polyglot.t("payment_methods.no_result")}
              subtitle={polyglot.t("payment_methods.no_result_subtitle")}
            >
              <Button.Medium
                kind={BUTTON.KIND.SECONDARY}
                LeftComponent={() => <Icon.Medium name="plus" />}
                onClick={() => setModalIsOpen(true)}
              >
                {polyglot.t("payment_method.add_card")}
              </Button.Medium>
            </NoResult>
          )}
          <PaymentMethodsUpdated.New
            isOpen={modalIsOpen}
            mangopay_cards={data?.mangopay_cards}
            onClose={() => setModalIsOpen(false)}
            isLoading={
              addPaymentMethod.isLoading ||
              isLoading ||
              addPaymentMethod.isSuccess
            }
            methods={[VISA_MASTERCARD]}
            onChange={(values) => {
              addPaymentMethod.mutate({
                token: values.token,
                current: values.current,
              });
            }}
          />
        </>
      ) : (
        <Block marginY={spacings.m}>
          <ManagePaymentMethodsSkeleton />
        </Block>
      )}
    </Block>
  );
};

export default ManagePaymentMethods;
