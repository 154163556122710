import { colors } from "@/assets/themes";
import { Body14, Body16, Icon, List } from "@/components";
import { LIST } from "@/components/Styles/variants";
import useJob from "@/modules/hooks/useJob";
import polyglot from "@/utils/polyglot";

const AddJobberAlert = () => {
  const { data, switchView } = useJob();
  if (!data.bookable) {
    return <></>;
  }
  return (
    <List.Item
      LeftComponent={() => (
        <Icon.XLarge name="user-plus" color={colors.primary} />
      )}
      withGutters
      chevron
      divider={false}
      shape={LIST.SHAPE.ROUND}
      css={`
        background-color: ${colors.primaryLight};
      `}
      onClick={() => {
        switchView();
      }}
    >
      <Body16 strong>{polyglot.t("job.add_jobber_alert_title")}</Body16>
      <Body14>{polyglot.t("job.add_jobber_alert_description")}</Body14>
    </List.Item>
  );
};

export default AddJobberAlert;
