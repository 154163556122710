import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useBreakpoints } from "@/modules/hooks";
import { borderWidth, colors, shadows, sizes, spacings } from "@/assets/themes";
import Block from "../Block";
import Button from "../Button";
import Icon from "../Icon";
import { BUTTON } from "../Styles/variants";
import Tab from "./Tab";

const StyledUl = styled.ul`
  border-bottom: solid 2px ${colors.border};
  flex-wrap: nowrap;
  position: relative;
  display: flex;
  align-items: stretch;
  padding: 0;
  margin: 0;
  width: 100%;
  > li {
    list-style-type: none;
    flex: ${({ fill }) => (fill ? 1 : "inherit")};
  }
`;

const StyledActive = styled(motion.div)`
  position: absolute;
  bottom: calc(${borderWidth.m} * -1);
  height: ${borderWidth.m};
  background-color: ${colors.primary};
`;

const StyledNavWrapper = styled.div`
  position: absolute;
  left: ${({ start }) => (start ? spacings.s : "inherit")};
  right: ${({ end }) => (end ? spacings.s : "inherit")};
  top: 0;
  bottom: ${borderWidth.m};
  display: flex;
  align-items: center;
  z-index: 2;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${({ start }) => (start ? `calc(${spacings.s} * -1)` : "inherit")};
    right: ${({ end }) => (end ? `calc(${spacings.s} * -1)` : "inherit")};
    width: ${sizes.size48};
    background: ${({ start }) =>
      start
        ? `linear-gradient(to right, ${colors.background}, transparent)`
        : `linear-gradient(to right, transparent, ${colors.background})`};
  }
  button {
    box-shadow: ${shadows.s};
  }
`;

const Tabs = ({
  onChange,
  children,
  value,
  onAnimationComplete,
  enableChangeOnSwipe = false,
  fill,
}) => {
  const [currActive, setCurrActive] = useState(value);
  const [activeStyle, setActiveStyle] = useState({ left: 0, width: 0 });
  const [isMounted, setIsMounted] = useState(false);
  const [lastScrollLeft, setLastScrollLeft] = useState(0);
  const [isEndReached, setIsEndReached] = useState(false);
  const [isStartReached, setIsStartReached] = useState(false);
  const breakpoints = useBreakpoints();
  const tabsRef = useRef([]);
  const wrapperRef = useRef();
  const endAnchorRef = useRef();
  const startAnchorRef = useRef();
  const isMobile = breakpoints.get({ xs: true, md: false });

  const validChildren = React.Children.toArray(children).filter(
    (child) => React.isValidElement(child) && child.type === Tab
  );

  useEffect(() => {
    if (value !== currActive) {
      setCurrActive(value);
    }
  }, [value]);

  const handleActive = () => {
    const activeChild = validChildren.find(
      (child) => child.props.value === value
    );
    const activeTabIndex = validChildren.indexOf(activeChild);
    const activeTab = tabsRef.current[activeTabIndex];

    if (activeTab) {
      const { offsetLeft, clientWidth } = activeTab;
      setActiveStyle({ left: offsetLeft, width: clientWidth });
    }
  };

  useEffect(() => {
    handleActive();
  }, [children, value]);

  const handleChange = (newValue) => {
    if (onChange) onChange(newValue);
  };

  const renderTabs = () =>
    validChildren.map((child, index) =>
      React.cloneElement(child, {
        ...child.props,
        ref: (el) => {
          tabsRef.current[index] = el;
        },
        fill,
        onClick: () => handleChange(child.props?.value),
        isActive: currActive === child.props?.value,
      })
    );

  const handleNavigationVisibility = ({
    scrollLeft,
    clientWidth,
    scrollWidth,
  }) => {
    if (scrollLeft === 0) {
      setIsStartReached(true);
    } else {
      setIsStartReached(false);
    }
    if (Math.ceil(scrollLeft + clientWidth) >= scrollWidth) {
      setIsEndReached(true);
    } else {
      setIsEndReached(false);
    }
  };

  const initialScroll = () => {
    const activeChild = validChildren.find(
      (child) => child.props.value === value
    );
    const activeTabIndex = validChildren.indexOf(activeChild);
    const activeTab = tabsRef.current[activeTabIndex];
    const wrapper = wrapperRef.current;

    if (activeTab && wrapper) {
      const { offsetLeft, clientWidth } = activeTab;
      const { scrollLeft, clientWidth: wrapperClientWidth } = wrapper;

      // Check if active tab is not fully visible in the viewport
      if (
        offsetLeft < scrollLeft ||
        offsetLeft + clientWidth > scrollLeft + wrapperClientWidth
      ) {
        wrapper.scrollTo({
          left: offsetLeft - wrapperClientWidth / 2 + clientWidth / 2,
        });
      }
    }
  };

  const handleScroll = () => {
    const wrapper = wrapperRef.current;
    const { scrollLeft, scrollWidth, clientWidth } = wrapper;
    if (isMobile && enableChangeOnSwipe) {
      let newActiveIndex = 0;

      const scrollPercentage = Math.ceil(
        (scrollLeft / (scrollWidth - clientWidth)) * 100
      );

      const tabPercentage = 100 / tabsRef.current.length;

      for (let i = 0; i < tabsRef.current.length; i += 1) {
        const tabLeftPercentage = i * tabPercentage;

        if (scrollPercentage >= tabLeftPercentage) {
          newActiveIndex = i;
        }
      }
      if (scrollPercentage >= 100) {
        newActiveIndex = tabsRef.current.length - 1;
      }

      const activeChild = validChildren.find(
        (child) => child.props.value === value
      );
      const activeTabIndex = validChildren.indexOf(activeChild);
      const direction = scrollLeft > lastScrollLeft ? "right" : "left";

      if (
        (direction === "right" && activeTabIndex <= newActiveIndex) ||
        (direction === "left" && activeTabIndex >= newActiveIndex)
      ) {
        handleChange(validChildren[newActiveIndex].props.value);
      }

      setLastScrollLeft(scrollLeft);
    }
    handleNavigationVisibility(wrapper);
  };

  const handleNavigationScroll = (amount) => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      wrapper.scrollTo({
        left: wrapper.scrollLeft + amount,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (wrapperRef.current) initialScroll();
  }, [wrapperRef.current]);

  useEffect(() => {
    const wrapper = wrapperRef.current;

    if (wrapper) {
      handleNavigationVisibility(wrapper);
      wrapper.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (wrapper) wrapper.removeEventListener("scroll", handleScroll);
    };
  }, [currActive, validChildren]);

  return (
    <Block position="relative" display="flex" alignItems="center">
      {!isStartReached && !isMobile && (
        <StyledNavWrapper start>
          <Button.Small
            kind={BUTTON.KIND.SECONDARY}
            shape={BUTTON.SHAPE.CIRCLE}
            onClick={() => handleNavigationScroll(-300)}
          >
            <Icon.Medium name="arrow-left" color={colors.body} />
          </Button.Small>
        </StyledNavWrapper>
      )}

      <Block
        overflowX="scroll"
        ref={wrapperRef}
        css={`
          scrollbar-width: none;
          width: 100%;
        `}
      >
        <StyledUl fill={fill}>
          <li
            ref={startAnchorRef}
            css={`
              display: block;
              height: 0;
              max-width: 1px;
            `}
          />
          {renderTabs()}
          <li
            ref={endAnchorRef}
            css={`
              display: block;
              height: 0;
              max-width: 1px;
            `}
          />
          <StyledActive
            initial={false}
            onAnimationComplete={() => {
              setIsMounted(true);
              if (isMounted && onAnimationComplete) onAnimationComplete();
            }}
            animate={{ left: activeStyle.left, width: activeStyle.width }}
            transition={
              isMounted
                ? { type: "spring", stiffness: 300, damping: 30 }
                : { duration: 0 }
            }
          />
        </StyledUl>
      </Block>
      {!isEndReached && !isMobile && (
        <StyledNavWrapper end>
          <Button.Small
            kind={BUTTON.KIND.SECONDARY}
            shape={BUTTON.SHAPE.CIRCLE}
            onClick={() => handleNavigationScroll(300)}
          >
            <Icon.Medium name="arrow-right" color={colors.body} />
          </Button.Small>
        </StyledNavWrapper>
      )}
    </Block>
  );
};

export default Tabs;
