import { sizes, spacings } from "../../../assets/themes";
import { Block, Skeleton } from "../../../components";

const ManagePaymentMethodsSkeleton = () => (
  <Skeleton.Group>
    <Skeleton width="100%" css="aspect-ratio: 1.7;" sb />
    <Block marginTop={spacings.l} marginBottom={spacings.m}>
      <Skeleton height={sizes.size18} width="70%" sb />
    </Block>
    <Skeleton height={sizes.size48} width="100%" rows={2} />
  </Skeleton.Group>
);

export default ManagePaymentMethodsSkeleton;
