import { FastField } from "formik";
import moment from "moment";
import { FormControl, Input } from "../../../../components";
import polyglot from "../../../../utils/polyglot";
import { CREDIT_CARD_EXP_DATE } from "../constants";

const ExpirationDateField = () => {
  const placeholder = `12/${moment().add(1, "year").format("YY")}`;

  return (
    <FastField name="cardExpirationDate">
      {({ field }) => (
        <FormControl
          label={polyglot.t("payment_methods.expiration_date")}
          css={`
            margin-bottom: 0;
          `}
        >
          <Input
            inputMode="numeric"
            autoComplete="cc-exp"
            placeholder={placeholder}
            mask={CREDIT_CARD_EXP_DATE}
            {...field}
          />
        </FormControl>
      )}
    </FastField>
  );
};

export default ExpirationDateField;
