import { BrowserRouter } from "react-router-dom";
import BaseApp from "../modules/BaseApp";
import JobberBooking from "../pages/jobber-booking/JobberBooking";

const JobberBookingApp = (props, rails) => () =>
  (
    <BaseApp {...props}>
      <BrowserRouter basename={rails.location.pathname}>
        <JobberBooking {...props} />
      </BrowserRouter>
    </BaseApp>
  );

export default JobberBookingApp;
