import moment from "moment";
import { colors, spacings } from "@/assets/themes";
import { Block, Body14, Progress } from "@/components";
import useJob from "@/modules/hooks/useJob";
import polyglot from "@/utils/polyglot";
import { getSelectedOrPaidOffers } from "../utils";

const ProgressSteps = () => {
  const { data } = useJob();

  const { accepted_offers, start_hour, date, end_date } = data;

  const getProgressStep = () => {
    const datetime = {
      start_hour,
      date,
      end_date,
    };
    const offers = getSelectedOrPaidOffers(accepted_offers);
    if (!offers || offers.length === 0) {
      return null;
    }
    const IS_DONE_BY_JOBBERS = offers.every(
      (offer) => !!offer.done_by_jobber_at
    );
    const IS_PAID = offers.every((offer) => offer.state === "paid");

    if (IS_DONE_BY_JOBBERS) {
      return {
        progress: 100,
        steps: 3,
        color: colors.primary,
        subtitle: polyglot.t("job.waiting_for_payment_validation"),
      };
    }
    if (IS_PAID) {
      return {
        progress: 100,
        steps: 3,
        color: colors.success,
        subtitle: polyglot.t("job.job_payment_done"),
      };
    }
    if (!datetime?.start_hour) {
      const startDateTime = moment(`${datetime.date} 00:00`);
      const now = moment();
      const timeFromNow = startDateTime.fromNow();

      if (now.isAfter(startDateTime)) {
        return {
          progress: 100,
          steps: 2,
          color: colors.primary,
          subtitle: polyglot.t("job.job_in_progress"),
        };
      }
      return {
        progress: 50,
        steps: 2,
        color: colors.primary,
        subtitle: polyglot.t("job.job_start_at_time", {
          time: timeFromNow,
        }),
      };
    }
    if (datetime?.start_hour) {
      const startDateTime = moment(
        `${datetime.date} ${
          datetime.start_hour % 1 === 0
            ? `${datetime.start_hour}:00`
            : `${parseInt(datetime.start_hour)}:30`
        }`
      );
      const now = moment();
      const timeFromNow = startDateTime.fromNow();

      if (now.isAfter(startDateTime)) {
        return {
          progress: (100 / 3) * 2,
          steps: 3,
          color: colors.primary,
          subtitle: polyglot.t("job.job_in_progress"),
        };
      }
      return {
        progress: 100 / 3,
        steps: 3,
        color: colors.primary,
        subtitle: polyglot.t("job.job_start_at_time", {
          time: timeFromNow,
        }),
      };
    }
  };

  return (
    <Block spaceY={spacings.s}>
      <Progress.Bar
        steps={getProgressStep().steps}
        value={getProgressStep().progress}
        css={`
          &::before {
            background-color: ${getProgressStep().color};
          }
        `}
      />
      <Body14 strong color={colors.muted}>
        {getProgressStep().subtitle}
      </Body14>
    </Block>
  );
};

export default ProgressSteps;
