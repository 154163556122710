import { colors, spacings } from "@/assets/themes";
import JobberPaymentDoneTodayEnhancer from "@/pages/Job/JobberPaymentDoneTodayEnhancer";
import JobHasBeenDoneEnhancer from "@/pages/Job/JobHasBeenDoneModal";
import {
  getInboxesUrl,
  getInstantPaymentOfferUrl,
  getValidationOfferUrl,
} from "@/pages/Job/utils";
import polyglot from "@/utils/polyglot";
import Block from "../Block";
import Button from "../Button";
import { BUTTON, POPOVER } from "../Styles/variants";
import { Body14, Body16 } from "../Text";
import Icon from "../Icon";
import Badge from "../Badge";
import Popover from "../popover/Popover";
import List from "../List";
import { formatPhone } from "@/utils";
import { useBreakpoints } from "@/modules/hooks";

const SelectedActions = ({ offer, disabled }) => {
  const breakpoints = useBreakpoints();
  return (
    <Block display="flex" flexDirection="column">
      <Block marginBottom={spacings.s} spaceY={spacings.s}>
        <Block display="flex" gap={spacings.s}>
          <Block flex="1">
            <Badge
              count={offer.inbox_messages_not_read}
              css={`
                display: flex;
              `}
            >
              <Button.Medium
                block
                kind={BUTTON.KIND.SECONDARY}
                href={
                  disabled
                    ? undefined
                    : getInboxesUrl(offer.inbox_id, "?from_job=true")
                }
                LeftComponent={() => <Icon.Large name="chat-solid" />}
                disabled={disabled}
                // css fix 320px responsive pb
                css="padding-left: 0px; padding-right: 0px;"
              >
                {polyglot.t("common.chat")}
              </Button.Medium>
            </Badge>
          </Block>
          <Block flex="1">
            <Popover.Enhancer
              trigger={POPOVER.TRIGGER_TYPE.CLICK}
              content={() => (
                <Popover.Elem.Menu>
                  <List.Item
                    withGutters
                    divider={false}
                    href={`tel:${offer.phone}`}
                    LeftComponent={() => (
                      <Icon.Medium name="phone" color={colors.primary} />
                    )}
                  >
                    <Body16 strong color={colors.primary}>
                      {formatPhone(offer.phone)}
                    </Body16>
                  </List.Item>
                </Popover.Elem.Menu>
              )}
            >
              <Button.Medium
                block
                kind={BUTTON.KIND.SECONDARY}
                disabled={disabled}
                LeftComponent={() => <Icon.Medium name="phone" />}
                // css fix 320px responsive pb
                css="padding-left: 0px; padding-right: 0px;"
                RightComponent={breakpoints.get({
                  xs: undefined,
                  md: () => <Icon.Small name="caret-down" />,
                })}
              >
                {polyglot.t("common.call")}
              </Button.Medium>
            </Popover.Enhancer>
          </Block>
        </Block>
        {!disabled && (
          <>
            {offer.instant_payment_payable && (
              <JobberPaymentDoneTodayEnhancer
                last_instant_payment={offer.last_instant_payment}
              >
                <Button.Medium
                  kind={BUTTON.KIND.TERTIARY}
                  block
                  href={getInstantPaymentOfferUrl(
                    offer.instant_payment_relation_id
                  )}
                >
                  {polyglot.t("job.send_payment")}
                </Button.Medium>
              </JobberPaymentDoneTodayEnhancer>
            )}
            {offer.payable && (
              <JobHasBeenDoneEnhancer>
                <Button.Medium
                  kind={BUTTON.KIND.TERTIARY}
                  block
                  href={getValidationOfferUrl(offer.id)}
                >
                  {polyglot.t("job.payment_validation_amount", {
                    total_price: polyglot.toSmartCurrency(offer.price, {
                      noDecimal: true,
                    }),
                  })}
                </Button.Medium>
              </JobHasBeenDoneEnhancer>
            )}
          </>
        )}
      </Block>
      {offer.instant_payment_payable && !disabled && (
        <Block marginTop={spacings.xs}>
          <Body14 color={colors.muted} align="center">
            {polyglot.t("job.booked_informations", {
              price_per_hour: polyglot.toSmartCurrency(offer.price_per_hour, {
                pricePerHour: true,
              }),
            })}
          </Body14>
        </Block>
      )}
    </Block>
  );
};

export default SelectedActions;
