import { Block } from "@/components";
import { getPaymentMethodBadge } from "./utils";
import { spacings } from "@/assets/themes";

const Methods = ({ methods }) => (
  <Block display="flex" gap={spacings.xxs}>
    {methods.map((method) => (
      <Block key={method}>
        <img
          src={getPaymentMethodBadge(method)}
          alt={method}
          width="35px"
          height="auto"
        />
      </Block>
    ))}
  </Block>
);

export default Methods;
