import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { spacings } from "../../assets/themes";
import { Block, Button } from "../../components";
import { useBreakpoints } from "../../modules/hooks";
import useJob from "../../modules/hooks/useJob";
import CustomContainer from "./CustomContainer";
import JobComments from "./JobComments";
import JobHelpBox from "./JobHelpBox";
import JobInformations from "./JobInformations";
import JobJobberJoinBox from "./JobJobberJoinBox";
import JobOffers from "./JobOffers";

const Job = () => {
  const { data, isPoster } = useJob();
  const breakpoints = useBreakpoints();

  return (
    <div>
      <Block marginBottom={spacings.m}>
        <Block display="flex" flexDirection={{ xs: "column", md: "row" }}>
          {/* fix : minWidth="0" prevent Carrousel extend parent size */}
          <Block flex="1" minWidth="0">
            <Block width="100%">
              <JobInformations />
              {!isPoster && (
                <CustomContainer>
                  <Block marginTop={spacings.m}>
                    <JobJobberJoinBox />
                  </Block>
                </CustomContainer>
              )}
              <JobOffers />
            </Block>
          </Block>
          {breakpoints.get({ xs: false, md: true }) && (
            <Block
              min-width={{ xs: "inherit", md: "300px", lg: "375px" }}
              max-width={{ xs: "inherit", md: "300px", lg: "375px" }}
              width={{ xs: "inherit", md: "300px", lg: "375px" }}
              marginLeft={{ xs: 0, md: spacings.l }}
            >
              <JobComments id={data.id} inModal={false} />
              {isPoster && <JobHelpBox />}
            </Block>
          )}
        </Block>
      </Block>
    </div>
  );
};
export default Job;
