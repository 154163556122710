import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { spacings } from "../../../../assets/themes";
import { Block } from "../../../../components";
import { useCategoryForm } from "../../../../modules/hooks";
import Recommendation from "../../common/Recommendation";
import RecommendationLoading from "../../common/RecommendationLoading";
import { useGetDistance } from "../../useGetDistance";
import AnimatedTruck from "../../../../assets/lottie/truck-loading.lottie";
import useCategoryValue from "../../useCategoryValue";
import polyglot from "../../../../utils/polyglot";

const RecommendationWrapper = () => {
  const [animationComplete, setAnimationComplete] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const { flowControl } = useCategoryForm();
  const { isLoading, isSuccess, data } = useGetDistance(values);
  const { getNbJobbers } = useCategoryValue();

  const [showLoading, setShowLoading] = useState(isLoading);

  const handleAnimationComplete = () => {
    setAnimationComplete(true);
  };

  useEffect(() => {
    flowControl.setNextIsLoading(showLoading);
    return () => {
      flowControl.setNextIsLoading(false);
    };
  }, [showLoading]);

  useEffect(() => {
    if (isSuccess && animationComplete) {
      setShowLoading(false);
      setFieldValue("nh_specific2[3]", data.distance_in_meters, false);
      setFieldValue("nh_specific2[4]", data.duration_in_seconds, false);
    }
  }, [isSuccess, animationComplete]);

  const getTruckBoolean = (value) => {
    if (value === 0) return false;
    if (value === 1) return true;
    return null;
  };

  return (
    <>
      {showLoading ? (
        <Block
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginY={spacings.xxl}
        >
          <RecommendationLoading
            animationData={AnimatedTruck}
            onAnimationComplete={handleAnimationComplete}
            title={polyglot.t("categories.calculate_perfect_move")}
            phrases={[
              polyglot.t("categories.calculate_move_volume"),
              polyglot.t("categories.calculate_distance"),
            ]}
          />
        </Block>
      ) : (
        <Recommendation
          nbHoursTitle={polyglot.t("categories.move_duration")}
          items={[
            {
              icon: "surface-small",
              label: polyglot.t("categories.move_volume"),
              value: `${values.nh_specific2[2]
                ?.toString()
                .replace(".", ",")} m³`,
            },
          ]}
          title={polyglot.t("categories.moving_recommendation_plural", {
            count: getNbJobbers(),
            nb_hours: polyglot.toDuration(values.nb_hours_calculated, true),
          })}
          distanceInMeters={data.distance_in_meters}
          truck={getTruckBoolean(values.nh_specific3)}
        />
      )}
    </>
  );
};
export default RecommendationWrapper;
