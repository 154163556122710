import { useState } from "react";
import { artwork } from "@/assets/categories";
import { colors, radius, sizes, spacings } from "@/assets/themes";
import {
  Block,
  Body14,
  Body16,
  Button,
  Col,
  Divider,
  H2,
  H3,
  Icon,
  ImageGallery,
  List,
  ReadMore,
  Row,
  Shape,
} from "@/components";
import { CardBase } from "@/components/Styles/Base";
import { BUTTON, SHAPE } from "@/components/Styles/variants";
import { useBreakpoints } from "@/modules/hooks";
import useJob from "@/modules/hooks/useJob";
import UserPreview from "@/pages-admin/UserPreview";
import { JOB } from "@/pages-admin/UserPreview/config";
import { BREAKPOINTS, getJobReopenUrl } from "@/utils";
import polyglot from "@/utils/polyglot";
import EditJobEnhancer from "./EditJobEnhancer";
import JobComments from "./JobComments";
import JobDetails from "./JobDetails";
import JobMobileHeader from "./JobMobileHeader";
import JobPrivateJobber from "./JobPrivateJobber";

const JobInformations = () => {
  const { data, isPoster, isAssistantInitial, switchView } = useJob();
  const breakpoint = useBreakpoints();
  const showEditJobButton = isPoster && !data.terminated;

  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [commentModalIsOpen, setCommentModalIsOpen] = useState(false);

  const handleToggleDetails = () => {
    setIsDetailsVisible((s) => !s);
  };

  const handleCloseCommentModal = () => {
    setCommentModalIsOpen(false);
  };

  const handleOpenCommentModal = () => {
    setCommentModalIsOpen(true);
  };

  return (
    <CardBase
      flat
      css={`
        overflow: hidden;
        border-radius: 0;
        @media screen and (min-width: ${BREAKPOINTS.md}px) {
          border-radius: ${radius.ml};
        }
      `}
    >
      <Block position="relative">
        <JobMobileHeader />
        <UserPreview kind={JOB} id={data.id}>
          <Block
            width="100%"
            maxHeight={140}
            minHeight={130}
            backgroundImage={`url(${artwork[data.category]})`}
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundPosition="bottom right"
            display="block"
            backgroundColor={colors.gray100}
            paddingBottom="25%"
            marginBottom={spacings.m}
          />
        </UserPreview>
        <Block paddingX={{ xs: spacings.m, md: spacings.ml }}>
          <Block
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            width="100%"
          >
            <Block>
              <UserPreview kind={JOB} id={data.id}>
                <Block marginBottom={spacings.xs}>
                  {breakpoint.get({
                    xs: <H3>{data.title}</H3>,
                    md: <H2>{data.title}</H2>,
                  })}
                </Block>
              </UserPreview>
              <Block marginBottom={spacings.sm}>
                <Body14 color={colors.muted}>
                  <Icon.Small name="map-marker" />
                  &nbsp;<span>{data.city}</span>
                  {data.nb_jobbers > 1 && (
                    <>
                      &nbsp;•&nbsp;
                      <Icon.Small name="user" />
                      &nbsp;
                      <span>
                        {polyglot.t("job.n_jobber_needed_plural", {
                          count: data.nb_jobbers,
                        })}
                      </span>
                    </>
                  )}
                  {!isPoster && (
                    <>
                      &nbsp;•&nbsp;
                      <span>
                        {polyglot.t("job.posted_by_first_name", {
                          first_name: data.poster?.first_name,
                        })}
                      </span>
                    </>
                  )}
                </Body14>
              </Block>
            </Block>
          </Block>
        </Block>
      </Block>
      <Block
        paddingX={{ xs: spacings.m, md: spacings.ml }}
        paddingBottom={{ xs: "0px", md: spacings.ml }}
        spaceY={spacings.m}
      >
        <Block spaceY={spacings.sm}>
          <Block display="flex" alignItems="center">
            <Shape.Small
              shape={SHAPE.SHAPE.CIRCLE}
              backgroundColor={colors.primaryLight}
              color={colors.primary}
            >
              <Icon.Medium name="calendar" />
            </Shape.Small>
            <Block marginLeft={spacings.s}>
              <Body16 strong>{data.datetime}</Body16>
            </Block>
          </Block>
          {data.duration_formatted && (
            <Block display="flex" alignItems="center">
              <Shape.Small
                shape={SHAPE.SHAPE.CIRCLE}
                backgroundColor={colors.primaryLight}
                color={colors.primary}
              >
                <Icon.Medium name="clock" />
              </Shape.Small>
              <Block marginLeft={spacings.s}>
                <Body16 strong>{data.duration_formatted}</Body16>
              </Block>
            </Block>
          )}
        </Block>
        {data.description && (
          <Block>
            <Body16>
              <ReadMore numberOfLines={2}>{data.description}</ReadMore>
            </Body16>
          </Block>
        )}
        {data.photos?.length > 0 && (
          <Block display="flex" flexWrap="wrap">
            <ImageGallery>
              {data.photos.map((image, i) => (
                <Block
                  key={`job-informations-image-${i}`}
                  srcSet={image.large}
                  width={{ xs: sizes.size54, lg: sizes.size72 }}
                  height={{ xs: sizes.size54, lg: sizes.size72 }}
                  borderRadius={radius.m}
                  backgroundImage={`url(${image.thumbnail})`}
                  backgroundSize="cover"
                  backgroundPosition="center"
                  display="block"
                  marginRight={spacings.s}
                />
              ))}
            </ImageGallery>
          </Block>
        )}

        {data.private_jobber && (
          <Block>
            <JobPrivateJobber />
          </Block>
        )}
        <JobDetails isVisible={isDetailsVisible} />

        {(isPoster || data.informations.length > 0) && (
          <Block marginBottom={{ xs: spacings.m, md: "0px" }}>
            <Row gutter={[spacings.s]}>
              {(isPoster || data.informations.length > 0) && (
                <Col size={showEditJobButton ? 6 : { xs: 12, lg: 6 }}>
                  <Button.Medium
                    block
                    kind={BUTTON.KIND.SECONDARY}
                    onClick={handleToggleDetails}
                  >
                    {isDetailsVisible
                      ? polyglot.t("common.see_less")
                      : polyglot.t("common.see_more")}
                  </Button.Medium>
                </Col>
              )}
              {showEditJobButton && (
                <Col size={6}>
                  <EditJobEnhancer>
                    <Button.Medium
                      block
                      kind={BUTTON.KIND.TERTIARY}
                      LeftComponent={() => <Icon.Large name="settings" />}
                    >
                      {polyglot.t("common.edit")}
                    </Button.Medium>
                  </EditJobEnhancer>
                </Col>
              )}
              {data.can_duplicate_job && (
                <Col size={{ xs: 12, lg: 6 }}>
                  <Button.Medium
                    block
                    kind={BUTTON.KIND.TERTIARY}
                    href={getJobReopenUrl({
                      id: data.id,
                      category: data.category,
                      offers: data.accepted_offers,
                      nbJobbers: data.nb_jobbers,
                    })}
                    RightComponent={() => <Icon.Large name="copy" />}
                  >
                    {polyglot.t("jobs.ask_again")}
                  </Button.Medium>
                </Col>
              )}
              {isAssistantInitial && (
                <Col size={12}>
                  <Button.Medium
                    block
                    onClick={() => {
                      switchView();
                    }}
                  >
                    {polyglot.t("job.back_to_assistant")}
                  </Button.Medium>
                </Col>
              )}
            </Row>
          </Block>
        )}
      </Block>
      {breakpoint.get({ xs: true, md: false }) && (
        <>
          <Divider.Group />
          <List.Item
            chevron
            withGutters
            divider={false}
            onClick={() => {
              handleOpenCommentModal();
            }}
            LeftComponent={() => (
              <Shape.Small
                backgroundColor={colors.primaryLight}
                shape={SHAPE.SHAPE.CIRCLE}
              >
                <Icon.Large name="comment" color={colors.primary} />
              </Shape.Small>
            )}
          >
            <Body16 strong>
              {polyglot.t("common.public_chat")} ({data.comments_count})
            </Body16>
            {data.last_comment ? (
              <Body14 numberOfLines={1} color={colors.muted}>
                {data.last_comment.first_name} : {data.last_comment.body}
              </Body14>
            ) : (
              <Body14 color={colors.muted}>
                {polyglot.t("common.public_chat_empty_message")}
              </Body14>
            )}
          </List.Item>
          <Divider.Group />
          <JobComments
            id={data.id}
            isOpen={commentModalIsOpen}
            onClose={handleCloseCommentModal}
            inModal
          />
        </>
      )}
    </CardBase>
  );
};
export default JobInformations;
