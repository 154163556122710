import styled, { css } from "styled-components";
import {
  borderWidth,
  colors,
  radius,
  shadows,
  sizes,
  spacings,
} from "../../assets/themes";
import { getCssProperty } from "./Helper";
import { CHECKBOX, INPUT } from "./variants";
import { BREAKPOINTS } from "../../utils";

const ButtonBase = styled.button`
  ${({ accentColor = "primary" }) => css`
    background: none;
    border: none;
    text-align: left;
    padding: 0;
    outline: ${borderWidth.l} solid ${colors.transparent};
    transition: 0.2s;
    &:focus,
    &:active {
      outline: ${borderWidth.l} solid ${colors[`${accentColor}Light`]};
    }
  `}
`;

const setInputSize = (size) => {
  const reduceBorder = (val) =>
    `${Number(getCssProperty(val).replace("px", "")) - 4}px`;

  if (size === INPUT.SIZE.LARGE) {
    return reduceBorder(sizes.size54);
  }
  if (size === INPUT.SIZE.SMALL) {
    return reduceBorder(sizes.size36);
  }
  return reduceBorder(sizes.size48);
};

const InputBase = styled.input`
  ${({ as, size }) =>
    as === null
      ? css`
          padding: ${spacings.s} ${spacings.sm};
        `
      : css`
          input {
            height: ${setInputSize(size)};
          }
          input,
          textarea {
            padding: ${spacings.s} ${spacings.sm};
          }
        `};
  ${({ error, shape, size }) => css`
    input,
    textarea {
      color: ${colors.body};
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        /* //remove browser autofill background */
        -webkit-background-clip: text;
      }
    }
    min-height: ${setInputSize(size)};
    width: 100%;
    border-radius: ${shape === INPUT.SHAPE.CIRCLE
      ? `calc((${setInputSize(size)} + ${borderWidth.m} * 2) / 2)`
      : radius.m};
    border: solid ${borderWidth.m} ${error ? colors.danger : colors.transparent};
    box-shadow: 0 0 0 0px ${colors.transparent};
    background-color: ${error ? colors.dangerLight : colors.backgroundGray};

    outline: 0;
    box-sizing: border-box;
    transition: 0.2s;
    line-height: 28px;

    &&::-webkit-search-cancel-button,
    input::-webkit-search-cancel-button,
    textarea::-webkit-search-cancel-button {
      display: none;
    }
  `};
  ${({ disabled }) =>
    disabled &&
    css`
      &,
      input,
      textarea {
        cursor: not-allowed;
        opacity: 0.7;
      }
    `};
  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer !important;
      input,
      textarea {
        cursor: pointer !important;
      }
    `};
  ${({ isFocus }) =>
    isFocus &&
    css`
      border: solid ${borderWidth.m} ${colors.primary};
      box-shadow: 0 0 0 ${borderWidth.l} ${colors.primary50};
      background-color: ${colors.backgroundGray};
    `}
`;

const CheckboxBase = styled.div`
  ${({ disabled, checked }) =>
    !disabled &&
    css`
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9;
        width: calc(${sizes.size20} + ${spacings.s});
        height: calc(${sizes.size20} + ${spacings.s});
      }
      &:hover {
        background: ${checked ? colors.overlayLight : colors.overlayDark};
      }
    `}
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${colors.background};
  border-radius: ${({ shape }) =>
    shape === CHECKBOX.SHAPE.CIRCLE ? radius.circle : radius.xs};
  width: ${sizes.size20};
  min-width: ${sizes.size20};
  height: ${sizes.size20};
  min-height: ${sizes.size20};
  margin: ${spacings.xxs};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`;

const CheckboxLabelBase = styled.label`
  display: flex;
  align-items: flex-start;
  font-weight: 400;
  margin: 0;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  pointer-events: ${({ readOnly }) => (readOnly ? "none" : "all")};
  ${({ disabled }) =>
    !disabled &&
    css`
      &:focus-within {
        ${CheckboxBase} {
          box-shadow: 0 0 0 ${borderWidth.l} ${colors.primary50};
          &:before {
            border-color: ${colors.primary};
          }
        }
      }
      &:hover {
        ${CheckboxBase} {
          &:after {
            background: ${({ checked }) =>
              checked ? colors.overlayLight : colors.overlayDark};
          }
        }
      }
      &:hover&:active {
        ${CheckboxBase} {
          &:after {
            background: ${({ checked }) =>
              checked ? colors.overlayDark : colors.overlayLight};
          }
        }
      }
    `}
`;

const ActiveWrapperBase = styled.div`
  > a {
    color: ${colors.muted};
    padding-left: ${spacings.s};
    padding-right: ${spacings.s};
    transition: none;
    &.active {
      color: ${colors.body};
      background: ${colors.gray50};
      [data-content="badge"] {
        &:before {
          color: ${colors.gray50}!important;
        }
      }
    }
    &:hover:not(.active) {
      color: ${colors.body};
      [data-content="badge"] {
        &:before {
          color: ${colors.gray50}!important;
        }
      }
    }
  }
`;

const CardBase = styled.div`
  box-shadow: ${({ flat }) => !flat && shadows.xs};
  border-radius: ${({ flat }) => (flat ? radius.l : radius.ml)};
  background: ${({ light }) =>
    light ? colors.backgroundLight : colors.background};
  border: ${({ flat, border }) =>
    (!flat || border) && `solid ${borderWidth.s} ${colors.border}`};
`;

export {
  ActiveWrapperBase,
  ButtonBase,
  CardBase,
  CheckboxBase,
  CheckboxLabelBase,
  InputBase,
};
