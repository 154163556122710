import { colors, shadows } from "@/assets/themes";
import { Block, Icon, Map, Shape } from "@/components";
import { getCssProperty } from "@/components/Styles/Helper";
import { SHAPE } from "@/components/Styles/variants";
import useJob from "@/modules/hooks/useJob";

const createCircle = (map, location) => {
  const circle = new window.woosmap.map.Circle({
    strokeColor: getCssProperty(colors.primary),
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: getCssProperty(colors.primary),
    fillOpacity: 0.2,
    map,
    center: { lat: location.latitude, lng: location.longitude },
    radius: 2500,
  });
  return circle;
};

const AssistantMap = () => {
  const { data } = useJob();

  const DEFAULT_ZOOM = !data.google_place_id ? 12 : 15;

  const handleApiLoaded = (map) => {
    if (!data.google_place_id) createCircle(map, data?.location);
  };

  return (
    <Block width="100%" height={224} overflow="hidden" tabIndex={-1}>
      <Map
        onApiLoaded={handleApiLoaded}
        defaultZoom={DEFAULT_ZOOM}
        defaultCenter={{
          lat: data?.location?.latitude,
          lng: data?.location?.longitude,
        }}
        zoomControl={false}
        gestureHandling="none"
        disableDefaultUI
      >
        <Map.Marker
          lat={data?.location?.latitude}
          lng={data?.location?.longitude}
        >
          <Shape.Medium
            shape={SHAPE.SHAPE.CIRCLE}
            backgroundColor={colors.primary}
            color={colors.background}
            css={`
              box-shadow: ${shadows.s};
              transform: translate(-50%, -50%);
            `}
          >
            <Icon.Large name="home-solid" />
          </Shape.Medium>
        </Map.Marker>
      </Map>
    </Block>
  );
};

export default AssistantMap;
