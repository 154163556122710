import moment from "moment";
import { borderWidth, colors } from "@/assets/themes";
import { Body14, Body16, List, Tag } from "@/components";
import { LIST, TAG } from "@/components/Styles/variants";
import { getPaymentMethodBadge } from "@/pages/common/payment-methods/utils";
import { formatCardNumber } from "@/utils";
import polyglot from "@/utils/polyglot";

const PaymentMethodItem = ({ card, onClick }) => (
  <List.Item
    key={card.id}
    shape={LIST.SHAPE.ROUND}
    chevron={!!onClick}
    divider={false}
    onClick={onClick}
    LeftComponent={() => (
      <img src={getPaymentMethodBadge(card.kind)} alt={card.kind} />
    )}
    RightComponent={
      card.current
        ? () => (
            <Tag.Small kind={TAG.KIND.WHITE}>
              {polyglot.t("common.default")}
            </Tag.Small>
          )
        : undefined
    }
    withGutters
    css={`
      border: solid ${borderWidth.s} ${colors.border};
    `}
  >
    <Body16 strong>{formatCardNumber(card.alias)}</Body16>
    <Body14 color={colors.muted}>
      {moment(card.expiration_date).format("MM/YY")}
    </Body14>
  </List.Item>
);

export default PaymentMethodItem;
