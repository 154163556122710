import { colors, radius, spacings } from "@/assets/themes";
import {
  Block,
  Body16,
  Button,
  ConditionnalWrapper,
  Container,
  Divider,
  H2,
  H3,
  Icon,
  List,
} from "@/components";
import JobOfferItem from "@/components/JobOfferItem";
import OfferJobberRating from "@/components/JobOfferItem/OfferJobberRating";
import { CardBase } from "@/components/Styles/Base";
import { BUTTON, LIST } from "@/components/Styles/variants";
import { useBreakpoints } from "@/modules/hooks";
import useJob from "@/modules/hooks/useJob";
import UserPreview from "@/pages-admin/UserPreview";
import { JOB } from "@/pages-admin/UserPreview/config";
import { getJobReopenUrl } from "@/utils";
import polyglot from "@/utils/polyglot";
import JobHelpBox from "../JobHelpBox";
import JobMobileHeader from "../JobMobileHeader";
import { getSelectedOrPaidOffers } from "../utils";
import AddJobberAlert from "./AddJobberAlert";
import AssistantDetails from "./AssistantDetails";
import AssistantMap from "./AssistantMap";
import ManageJob from "./ManageJob";
import ProgressSteps from "./ProgressSteps";

const Assistant = () => {
  const { data } = useJob();
  const breakpoints = useBreakpoints();

  return (
    <Block
      display="grid"
      gridCols={{ xs: "1fr", md: "1fr 300px", lg: "1fr 375px" }}
      backgroundColor={{ xs: colors.background, md: colors.transparent }}
      paddingBottom={{ xs: spacings.m, md: spacings.l }}
      gap={spacings.l}
    >
      <Block position="relative">
        <JobMobileHeader />
        <Block
          paddingBottom={{ xs: "0px", md: spacings.ml }}
          borderRadius={{ xs: "0px", md: radius.ml }}
          overflow="hidden"
          spaceY={{ xs: spacings.sm, md: spacings.m }}
          backgroundColor={{ xs: colors.transparent, md: colors.background }}
        >
          <AssistantMap />
          <Block
            spaceY={{ xs: spacings.sm, md: spacings.m }}
            paddingX={{ xs: spacings.m, md: spacings.ml }}
          >
            <Block spaceY={spacings.s}>
              <UserPreview kind={JOB} id={data.id}>
                <Block marginBottom={spacings.xs}>
                  {breakpoints.get({
                    xs: <H3>{data.title}</H3>,
                    md: <H2>{data.title}</H2>,
                  })}
                </Block>
              </UserPreview>
              <ProgressSteps />
            </Block>
            <CardBase
              css={`
                padding: ${spacings.xs} 0;
                background-color: ${colors.backgroundLight};
              `}
              flat
            >
              <List.Group>
                <List.Item
                  withGutters
                  size={LIST.SIZE.COMPACT}
                  LeftComponent={() => <Icon.Large name="calendar" />}
                >
                  <Body16
                    css={`
                      margin: ${spacings.xs} 0px;
                    `}
                  >
                    {data.date_formatted}
                    {data.time_formatted ? ` • ${data.time_formatted}` : ""}
                  </Body16>
                </List.Item>
                <List.Item
                  withGutters
                  size={LIST.SIZE.COMPACT}
                  LeftComponent={() => <Icon.Large name="home" />}
                >
                  <Body16
                    css={`
                      margin: ${spacings.xs} 0px;
                    `}
                  >
                    {data.address}
                  </Body16>
                </List.Item>
              </List.Group>
            </CardBase>
            <AddJobberAlert />
            {data.can_duplicate_job && (
              <Button.Medium
                block
                kind={BUTTON.KIND.TERTIARY}
                href={getJobReopenUrl({
                  id: data.id,
                  category: data.category,
                  offers: data.accepted_offers,
                  nbJobbers: data.nb_jobbers,
                })}
                RightComponent={() => <Icon.Large name="copy" />}
              >
                {polyglot.t("jobs.ask_again")}
              </Button.Medium>
            )}
          </Block>
          <Block paddingX={{ xs: spacings.m, md: spacings.ml }}>
            {getSelectedOrPaidOffers(data.accepted_offers).length > 0 && (
              <CardBase flat border>
                {getSelectedOrPaidOffers(data.accepted_offers).map(
                  (offer, i, arr) => (
                    <>
                      <Block key={`job-offer-${offer.jobber.id}`}>
                        <JobOfferItem offer={offer} />
                      </Block>
                      <Block paddingX={{ xs: spacings.m, md: spacings.ml }}>
                        {i < arr.length - 1 && <Divider.Cell />}
                      </Block>
                    </>
                  )
                )}
              </CardBase>
            )}
          </Block>
        </Block>
        <Block>
          <ConditionnalWrapper
            condition={breakpoints.get({ xs: true, md: false })}
            wrapper={(children) => (
              <Container.Medium>{children}</Container.Medium>
            )}
          >
            {getSelectedOrPaidOffers(data.accepted_offers).map(
              (offer, _, arr) => {
                if (offer.rateable || offer.rate) {
                  return (
                    <OfferJobberRating
                      openInModal
                      backgroundColor={breakpoints.get({
                        xs: colors.backgroundLight,
                        md: colors.background,
                      })}
                      id={data.additional_informations?.offer_id || offer.id}
                      rate={offer.rate}
                      rateable={offer.rateable}
                      jobber={offer.jobber}
                      renderHeader={({ children, ...rest }) => (
                        <List.Header RightComponent={rest.RightComponent}>
                          {arr.length > 1
                            ? polyglot.t("job.rating_of_first_name", {
                                first_name: offer.jobber.first_name,
                              })
                            : polyglot.t("common.rating")}
                        </List.Header>
                      )}
                    />
                  );
                }
                return null;
              }
            )}
            <ManageJob />
            <AssistantDetails />
          </ConditionnalWrapper>
        </Block>
      </Block>
      {breakpoints.get({ xs: false, md: true }) && (
        <Block>
          <JobHelpBox />
        </Block>
      )}
    </Block>
  );
};

export default Assistant;
