export const ACCOUNT_INFORMATIONS_PATH = "/account/informations";
export const DELETE_ACCOUNT_PATH = "/account/delete_account";
export const ACCOUNT_WALLET_PATH = "/account/wallet";
export const ACCOUNT_CESU_PATH = "/account/cesu";
export const ACCOUNT_NOTIFICATIONS_PATH = "/account/notifications";
export const ACCOUNT_BILLING_PATH = "/account/billing";
export const ACCOUNT_COMPANY_PATH = "/account/company";
export const ACCOUNT_TAXES_PATH = "/account/taxes";
export const ACCOUNT_TAXES_PAY_PATH = "/account/taxes/:id";
export const ACCOUNT_BLOCKED_JOBBERS_PATH = "/account/blocked_jobbers";
export const ACCOUNT_PAYMENT_METHODS_PATH = "/mangopay_cards";
export const JOBBER_PROFILE_PATH = "/jobber/:id";
export const OFFER_PATH = "/offers/:id";

export const DASHBOARD_PATH = "/dashboard";
export const DASHBOARD_EXTRA_RATING_PATH = "/dashboard/offers/:id/extra_rates";
export const NOTIFICATIONS_PATH = "/notifications";
export const HOME_PATH = "/home";
export const INBOXES_PATH = "/inboxes";
export const ACCOUNT_PATH = "/account";
export const TICKETS_PATH = "/tickets";
export const TICKETS_NEW_PATH = "/tickets/new";
export const REFERRAL_PATH = "/invite-friends";
