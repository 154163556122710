/* eslint-disable import/no-unresolved */
import { useCallback, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import CesuSrc from "@/assets/images/banners/cesu-rc.svg";
// import FirstTimeSrc from "@/assets/images/banners/tax-credit-bg.svg";
import AppRcSrc from "@/assets/images/banners/app-rc.svg";
import CesuReminderRcSrc from "@/assets/images/banners/cesu-reminder-rc.svg";
import Valentinesday25Src from "@/assets/images/banners/valentinesday25-rc.svg";
import FirstTimeRcSrc from "@/assets/images/banners/first-time-rc.png";
import GiftCardSrc from "@/assets/images/banners/gift-card-bg.svg";
import NewBabySittingBgSrc from "@/assets/images/banners/new-baby-sitting-bg.svg";
import NewBabySittingRcSrc from "@/assets/images/banners/new-baby-sitting-rc.svg";
import ViewyRcSrc from "@/assets/images/banners/viewy-rc.lottie";
import ReferralSrc from "@/assets/images/banners/referral-bg.svg";
import TaxesSrc from "@/assets/images/banners/tax-credit-bg.svg";
import TaxesRcSrc from "@/assets/images/banners/tax-credit-rc.svg";
import { Block } from "@/components";
import { getStoreBadgesSrc } from "@/utils/get-store-badges-src";
import { colors, sizes, spacings } from "../../assets/themes";
import {
  ACCOUNT_CESU_PATH,
  ACCOUNT_TAXES_PATH,
  REFERRAL_PATH,
} from "../../pages/dashboard/config/routes";
import polyglot from "../../utils/polyglot";
import { DashboardContext } from "../contexts";
import useBreakpoints from "./useBreakpoints";
import useLocalStorage from "./useLocalStorage";

const HIDDEN_BANNERS_KEY = "homeHiddenBanners";

export default function useBanners() {
  const { public_pages } = useContext(DashboardContext);
  const breakpoints = useBreakpoints();
  const history = useHistory();

  const banners = {
    viewy: {
      title: polyglot.t("viewy.banner_title"),
      rightBackgroundLottie: ViewyRcSrc,
      backgroundColor: "linear-gradient(103deg, #FE2369 2.41%, #F8BB52 93.29%)",
      href: "https://viewy.fr/marque/yoojo",
      buttonTitle: polyglot.t("common.discover"),
      titleFontSize: breakpoints.get({ xs: "20px", sm: "24px", lg: "28px" }),
      accent: "dark",
      target: "_blank",
      event: "viewy",
      closable: true,
    },
    valentinesday: {
      title: polyglot.t("valentinesday25.banner_title", {
        amount: polyglot.toSmartCurrency(5, { noDecimal: true }),
      }),
      subtitle: polyglot.t("valentinesday25.banner_subtitle"),
      rightBackgroundImage: Valentinesday25Src,
      backgroundColor: colors.danger50,
      onClick: () => history.push("/home?category=99"),
      buttonTitle: polyglot.t("common.discover"),
      accent: "light",
      bodyFontSize: breakpoints.get({
        xs: "13px",
        lg: "var(--font-size-body18)",
      }),
      event: "valentinesday25",
      closable: true,
    },
    cesu_reminder: {
      backgroundColor: colors.primary,
      rightBackgroundImage: CesuReminderRcSrc,
      title: "Vos tickets CESU expirent bientôt !",
      subtitle:
        "Pensez à dématérialiser vos CESU et à les utiliser sur Yoojo avant le 31 janvier.",
      href: ACCOUNT_CESU_PATH,
      accent: "dark",
      respawnAfterDays: 15,
      closable: true,
      buttonTitle: "Utiliser mes tickets CESU",
      event: "cesu_reminder",
    },
    gift_card: {
      backgroundImage: GiftCardSrc,
      title: polyglot.t("banners.gift_card_title"),
      subtitle: polyglot.t("banners.gift_card_subtitle"),
      href: public_pages.gift_card,
      accent: "dark",
      buttonTitle: polyglot.t("common.discover"),
      event: "gift_card",
    },
    ...(polyglot.country === "fr"
      ? {
          taxes: {
            backgroundImage: TaxesSrc,
            backgroundColor: colors.primaryLight,
            rightBackgroundImage: TaxesRcSrc,
            title: "Bénéficiez du crédit d’impôt",
            subtitle: "Réduisez le coût de vos services à domicile.",
            href: ACCOUNT_TAXES_PATH,
            accent: "light",
            buttonTitle: polyglot.t("common.discover"),
            event: "home_tax_credit",
          },
        }
      : []),
    download_app: {
      rightBackgroundImage: AppRcSrc,
      backgroundColor: colors.primary,
      title: polyglot.t("downloadAppModal.title"),
      subtitle: polyglot.t("downloadAppModal.body"),
      href: public_pages.mobile_app,
      accent: "dark",
      bodyFontSize: breakpoints.get({
        xs: "13px",
        lg: "var(--font-size-body18)",
      }),
      // titleFontSize: "20px",
      event: "download_app",
      closable: true,
      renderButton: () =>
        breakpoints.get({
          xs: (
            <Block display="flex" gap={spacings.xs}>
              <img
                alt="Apple store"
                src={getStoreBadgesSrc().ios}
                css={`
                  height: ${sizes.size20};
                `}
              />
              <img
                alt="Google play"
                src={getStoreBadgesSrc().android}
                css={`
                  height: ${sizes.size20};
                `}
              />
            </Block>
          ),
          md: (
            <Block display="flex" gap={spacings.xs}>
              <img
                alt="Apple store"
                src={getStoreBadgesSrc().ios}
                css={`
                  height: ${sizes.size36};
                `}
              />
              <img
                alt="Google play"
                src={getStoreBadgesSrc().android}
                css={`
                  height: ${sizes.size36};
                `}
              />
            </Block>
          ),
        }),
    },
    new_baby_sitting: {
      rightBackgroundImage: NewBabySittingRcSrc,
      backgroundImage: NewBabySittingBgSrc,
      backgroundColor: colors.pink500,
      title: polyglot.t("banners.baby_sitting_new"),
      subtitle: polyglot.t("banners.baby_sitting_book_baby_sitter"),
      href: public_pages.baby_sitting,
      bodyFontSize: breakpoints.get({
        xs: "13px",
        lg: "var(--font-size-body18)",
      }),
      accent: "light",
      event: "new_baby_sitting",
      closable: true,
    },
    first_time: {
      rightBackgroundImage: FirstTimeRcSrc,
      backgroundColor: colors.primary500,
      title: polyglot.t("banners.it_is_your_first_name"),
      subtitle: polyglot.t("banners.discover_what_we_can_do"),
      href: public_pages.who_are_we,
      event: "home_first_time_banner",
    },
    referral: {
      backgroundImage: ReferralSrc,
      backgroundColor: colors.turquoise700,
      title: polyglot.t("referral.banner_title"),
      subtitle: polyglot.t("referral.banner_subtitle"),
      href: REFERRAL_PATH,

      event: "home_referral",
    },
    ...(polyglot.country === "fr"
      ? {
          cesu: {
            rightBackgroundImage: CesuSrc,
            backgroundColor: colors.primaryLight,
            rightBackgroundColor: colors.primary,
            title: polyglot.t("banners.pay_with_cesu"),
            subtitle: polyglot.t("banners.book_jobbers_with_cesu"),
            href: public_pages.cesu,
            accent: "light",
            event: "home_cesu",
          },
        }
      : []),
  };

  const [storedHiddenBanners, setStoredHiddenBanners] = useLocalStorage(
    HIDDEN_BANNERS_KEY,
    []
  );

  const getValidStoreBanners = (storeBanners) => {
    const now = moment();
    return storeBanners.filter((banner) =>
      banner.expiresAt ? moment(banner.expiresAt).isAfter(now) : true
    );
  };

  const getVisibleBannersRef = useRef(null);

  const hide = useCallback(
    (name, respawnAfterDays) => {
      const expiresAt = respawnAfterDays
        ? moment().add(respawnAfterDays, "days").valueOf()
        : null;

      const newHiddenBanners = [
        ...storedHiddenBanners.filter((banner) => banner.name !== name),
        { name, expiresAt },
      ];
      setStoredHiddenBanners(newHiddenBanners);
      window.dispatchEvent(
        new CustomEvent("banners-updated", {
          detail: {
            banners: getVisibleBannersRef.current(banners, newHiddenBanners),
          },
        })
      );
    },
    [storedHiddenBanners, setStoredHiddenBanners]
  );

  const getVisibleBanners = useCallback(
    (currBanners, hiddenBanners = storedHiddenBanners) =>
      Object.entries(currBanners).reduce((acc, [name, banner]) => {
        if (hiddenBanners.findIndex((banner) => banner.name === name) === -1) {
          acc[name] = {
            ...banner,
            hide: () => hide(name, banner.respawnAfterDays),
          };
        }
        return acc;
      }, {}),
    [hide]
  );

  useEffect(() => {
    getVisibleBannersRef.current = getVisibleBanners;
  }, [getVisibleBanners]);

  const subscribe = useCallback((callback) => {
    const handleBannersUpdate = (event) => {
      callback(event?.detail?.banners || getVisibleBanners(banners));
    };
    handleBannersUpdate();
    window.addEventListener("banners-updated", handleBannersUpdate);
    return () => {
      window.removeEventListener("banners-updated", handleBannersUpdate);
    };
  }, []);

  useEffect(() => {
    const validBanners = getValidStoreBanners(storedHiddenBanners);
    setStoredHiddenBanners(validBanners);
    window.dispatchEvent(
      new CustomEvent("banners-updated", {
        detail: {
          banners: getVisibleBanners(banners, validBanners),
        },
      })
    );
  }, []);

  return {
    banners: getVisibleBanners(banners),
    subscribe,
  };
}
