import Fuse from "fuse.js";
import { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { colors, radius, spacings } from "@/assets/themes";
import { Block, Body16, Button, Icon, Input } from "@/components";
import { BUTTON, INPUT } from "@/components/Styles/variants";
import { useAdminPreview, useToast } from "@/modules/hooks";
import { formatPhone } from "@/utils";
import ActionItem from "../ActionItem";
import InformationItem from "../InformationItem";
import { JOB, addFromParam, getQueryKey } from "../config";
// eslint-disable-next-line import/no-cycle
import PreviewElemJobCustomer from "../PreviewElemJobCustomer";
// eslint-disable-next-line import/no-cycle
import PreviewElemJobOffer from "../PreviewElemJobOffer";
import { api } from "@/utils/axios";

const useJobConfig = ({ id }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { data, dataUpdateCount } = queryClient.getQueryState([
    getQueryKey({ kind: JOB, id }),
  ]);
  const adminPreview = useAdminPreview();

  const handleCopy = (phrase, value) => {
    navigator.clipboard.writeText(value);
    toast.remove();
    toast.success(phrase);
  };

  const informations = useCallback(
    () =>
      data && (
        <>
          <InformationItem
            title="ID"
            icon="copy"
            value={data?.offseted_id}
            onClick={() => {
              handleCopy(
                "L'id a été copié dans le presse papier",
                data?.offseted_id
              );
            }}
          />
          <PreviewElemJobCustomer
            customer={data?.customer}
            concierge_customer={data?.concierge_customer}
          />
          {data?.concierge_customer && (
            <>
              <InformationItem
                title="Client conciergerie"
                divider={false}
                value={`${data?.concierge_customer?.first_name} ${data?.concierge_customer?.last_name}`}
              />
              <InformationItem
                title=" • Téléphone"
                divider={false}
                href={`tel:${data?.concierge_customer?.phone}`}
                AfterComponent={() => (
                  <Block marginRight={6}>
                    <Button.Small
                      kind={BUTTON.KIND.MINIMAL}
                      shape={BUTTON.SHAPE.CIRCLE}
                      onClick={() =>
                        handleCopy(
                          "Le numéro de téléphone a été copié dans le presse papier",
                          data?.concierge_customer?.phone
                        )
                      }
                    >
                      <Icon.Medium name="copy" color={colors.primary} />
                    </Button.Small>
                  </Block>
                )}
                RightComponent={() => (
                  <Block
                    marginRight={`calc(${spacings.m} * -1)`}
                    display="flex"
                    alignItems="center"
                  >
                    <Block marginRight={spacings.xs}>
                      <Icon.Medium name="phone" color={colors.primary} />
                    </Block>
                    <Body16 color={colors.primary} strong>
                      {formatPhone(data?.concierge_customer?.phone, {
                        international: true,
                      })}
                    </Body16>
                  </Block>
                )}
              />
              <InformationItem
                title=" • E-mail"
                icon="copy"
                onClick={() => {
                  handleCopy(
                    "L'email a été copié dans le presse papier",
                    data?.concierge_customer?.email
                  );
                }}
                value={data?.concierge_customer?.email}
              />
            </>
          )}
          <InformationItem
            title="Code postal"
            icon="copy"
            onClick={() => {
              handleCopy(
                "Le code postal a été copié dans le presse papier",
                data?.city
              );
            }}
            value={data?.city}
          />
          <InformationItem
            title="Adresse"
            icon="copy"
            onClick={() => {
              handleCopy(
                "L'adresse a été copié dans le presse papier",
                `${data?.address}, ${data?.city}`
              );
            }}
            value={data?.address}
          />
          {data?.photos?.length > 0 && (
            <>
              <InformationItem title="Photos">
                <Block display="flex" gap={spacings.xs}>
                  {data?.photos.map((photo) => (
                    <a href={photo.large} target="_blank" rel="noreferrer">
                      <img
                        src={photo.thumbnail}
                        alt=""
                        css={`width: 24px; height: 24px; object-fit: cover; border-radius ${radius.s}`}
                      />
                    </a>
                  ))}
                </Block>
              </InformationItem>
            </>
          )}
          <InformationItem
            title="Yoojo Cover Plus"
            icon={data?.cover === "plus" ? "shield-plus-solid" : null}
            value={data?.cover === "plus" ? "Oui" : "Non"}
          />
        </>
      ),
    [dataUpdateCount]
  );

  const tabs = [
    {
      title: `Offres (${data?.offers?.length})`,
      disabled: data?.offers?.length === 0,
    },
    {
      title: "Gérer le job",
      disabled: data?.customer?.deleted,
    },
  ];

  const [term, setTerm] = useState("");
  const [offers, setOffers] = useState([]);

  const fuse = new Fuse(data?.offers, {
    keys: ["jobber.first_name", "jobber.last_name", "jobber.id"],
  });

  useEffect(() => {
    if (data) {
      setOffers(data?.offers);
    }
  }, [data]);

  const handleSearch = (e) => {
    const { value } = e?.target;
    setTerm(value);
    if (value) {
      const res = fuse.search(value);
      setOffers(res.map((r) => r.item));
    } else {
      setOffers(data?.offers);
    }
  };

  const menu = useCallback(
    () =>
      data && [
        offers.length > 0 ? (
          <div>
            <Block marginTop={spacings.m} marginX={spacings.m}>
              <Input
                type="search"
                value={term}
                size={INPUT.SIZE.SMALL}
                onChange={handleSearch}
                placeholder="Rechercher par nom ou id."
              />
            </Block>
            {offers.map((offer, index, arr) => (
              <PreviewElemJobOffer
                offer={offer}
                key={`job-${data?.id}-offer-${offer.jobber.id}`}
                divider={index < arr.length - 1}
              />
            ))}
          </div>
        ) : (
          []
        ),
        [
          [
            {
              title: "Modifier le job",
              href: addFromParam(`/kiss/jobs/${data.offseted_id}/edit`),
              icon: "edit",
            },
            {
              title: "Modifier la date et l'heure",
              href: `/kiss/jobs/edit_datetime?id=${data.offseted_id}&from=${window.location.pathname}`,
              icon: "calendar-edit",
            },
            {
              title: "Faire postuler un jobber",
              href: `/kiss/offers/new?job_id_offseted=${data.offseted_id}`,
              icon: "user-check",
            },
            {
              title: "Ouvrir l'historique du job",
              href: `/kiss/jobs/historic?id=${data.offseted_id}`,
              icon: "retry",
            },
            {
              title: `Voir les notes admin (${data.admin_notes_count})`,
              onClick: () =>
                adminPreview.notes.show({ job: data, id: data?.id }),
              icon: "exclamation-circle",
            },
            {
              title: "Voir les tickets BO",
              href: `/kiss/search/tickets?search[job_id_offseted]=${data.offseted_id}`,
              icon: "tag",
            },
          ].map((config, index) => (
            <ActionItem
              key={`preview-option-job-${data?.id}-${index}`}
              {...config}
            />
          )),
        ],
      ],
    [offers, dataUpdateCount]
  );

  return { informations, menu, tabs };
};

export default useJobConfig;
