import { useFormikContext } from "formik";
import React, { useEffect, useImperativeHandle } from "react";
import { spacings } from "../../../assets/themes";
import { Alert, Block, Divider } from "../../../components";
import polyglot from "../../../utils/polyglot";
import { CreditCardFields } from "./credit-card-fields";

const NewCreditCard = React.forwardRef(
  ({ needCCCheck, withGutters, disabled, methods, mangopay_cards }, ref) => {
    const { values, initialValues, setValues, validateForm } =
      useFormikContext();

    const handleReset = () => {
      setValues({
        default_payment_method: "",
        payment_method: "",
        card_number: "",
        card_type: "",
        token: "",
        cardNumber: "",
        cardExpirationDate: "",
        cardCvx: "",
        cardType: "",
        current: false,
      });
    };

    useImperativeHandle(
      ref,
      () => ({
        reset: handleReset,
      }),
      [values]
    );

    const handleCollapse = (arr) => {
      const newArr = {
        card_number: initialValues.card_number,
        card_type: initialValues.card_type,
        token: initialValues.token,
        cardNumber: "",
        cardExpirationDate: "",
        cardCvx: "",
        cardType: "",
        ...(arr || []),
      };
      setValues((s) => ({ ...s, ...newArr }), false);
      setTimeout(() => {
        validateForm();
      }, 0);
    };

    useEffect(() => {
      if (disabled) {
        handleCollapse({
          payment_method: initialValues.payment_method,
        });
      }
    }, [disabled]);

    const handleExpend = () => {
      const newArr = {
        card_number: "",
        card_type: "",
        token: "",
        payment_method: null,
        current: mangopay_cards?.length >= 1,
      };
      setValues((s) => ({ ...s, ...newArr }), false);
      setTimeout(() => {
        validateForm();
      }, 0);
    };

    useEffect(() => {
      handleExpend();
      return () => {
        handleCollapse();
      };
    }, []);

    return (
      <Block
        css={`
          margin: 0 ${withGutters ? spacings.m : 0};
        `}
      >
        <Divider.Cell />
        <Block marginTop={spacings.m}>
          {needCCCheck && (
            <Block marginBottom={spacings.s}>
              <Alert.Low
                title={polyglot.t(
                  "payment_method.you_credit_card_must_be_verified"
                )}
              />
            </Block>
          )}
          <CreditCardFields methods={methods} mangopay_cards={mangopay_cards} />
        </Block>
      </Block>
    );
  }
);

export default NewCreditCard;
