import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { spacings } from "../assets/themes";
import {
  Alert,
  Button,
  DatePicker,
  FormControl,
  Modal,
  Select,
  Textarea,
} from "../components";
import { formatTo } from "../components/DatePicker/utils";
import { ALERT } from "../components/Styles/variants";
import { useJobAvailableHours } from "../modules/hooks";
import { useGetDateTimeChange } from "../modules/routes/dashboard-routes";
import { formatDate } from "../utils";
import { getMinDate } from "../utils/job-date-time";
import polyglot from "../utils/polyglot";

const MAX_COMMENT_LENGTH = 120;

const validationSchema = yup.object().shape({
  date: yup.string().required(),
  start_hour: yup.number().required(),
  comment: yup.string(),
});

const Wrapper = ({ children, inContent, isOpen, onClose }) =>
  !inContent ? (
    <Modal.Small isOpen={isOpen} onClose={onClose} fullScreenOnMobile>
      {children}
    </Modal.Small>
  ) : (
    children
  );

const DATE_FORMAT = "YYYY-MM-DD";

const EditDateTime = ({
  isOpen,
  onClose,
  inContent,
  isBooked,
  discussable_id,
  title,
  query,
  children,
  onBack,
  canBack = true,
}) => {
  const queryClient = useQueryClient();
  const [submitIsLoading, setSubmitIsLoading] = useState(false);
  const [currMonth, setCurrMonth] = useState(
    formatTo(getMinDate(), null, DATE_FORMAT)
  );
  const jobAvailableHours = useJobAvailableHours({ format: DATE_FORMAT });

  const {
    data,
    isLoading = true,
    refetch,
  } = useGetDateTimeChange(
    { id: discussable_id, date: currMonth },
    { enabled: !!isOpen && !!isBooked }
  );

  const getDataByValue = (selectedDate) =>
    queryClient.getQueryData([
      `DATE_TIME_CHANGE_REQUEST`,
      {
        date: formatDate(selectedDate || currMonth, "YYYY-MM", DATE_FORMAT),
        id: discussable_id,
      },
    ]);

  const getOptsHours = (date) =>
    jobAvailableHours.getOptions({
      date,
      availabilities: getDataByValue(date)?.availabilities,
    });

  useEffect(() => {
    if (isBooked) refetch();
  }, [currMonth]);

  const handleMonthChange = (date) => {
    setCurrMonth(formatTo(date, null, DATE_FORMAT));
  };

  const getDisabledDate = (date) =>
    data?.availabilities[formatTo(date, null, DATE_FORMAT)]?.length === 0;

  useEffect(() => {
    // a local "isLoading" is for handle case that need to stop after redirection, and loading need to stop after page unmount.
    // because react query is global so cant handle if modal is closed.
    if (query.isLoading) setSubmitIsLoading(true);
    else setSubmitIsLoading(false);
  }, [query.isLoading]);

  const handleDayClick = (date, { values, setFieldValue, setFieldError }) => {
    setFieldValue("date", date);
    const currSelectedOption = getOptsHours(date).find(
      (o) => o.value === values.start_hour
    );
    if (
      values.start_hour?.toString() &&
      (!currSelectedOption || currSelectedOption.disabled)
    ) {
      setFieldValue("start_hour", "", false);
      setTimeout(() => {
        setFieldError("start_hour", polyglot.t("common.errors.field_required"));
      }, 0);
    }
  };

  const handleSubmit = (values) => {
    query.mutate({ ...values });
  };

  return (
    <Wrapper isOpen={isOpen} onClose={onClose} inContent={inContent}>
      <Modal.Item.Header
        onClose={onClose}
        onBack={inContent && canBack ? () => onBack && onBack() : null}
      >
        {title ? (
          <Modal.Item.Title>{title}</Modal.Item.Title>
        ) : (
          <>
            <Modal.Item.Title>
              {isBooked
                ? polyglot.t("new_date_time_change.modal.title")
                : polyglot.t("modal_edit_datetime.title")}
            </Modal.Item.Title>
            {isBooked && (
              <Modal.Item.Subtitle>
                {polyglot.t("new_date_time_change.move_subtitle")}
              </Modal.Item.Subtitle>
            )}
          </>
        )}
      </Modal.Item.Header>

      <Formik
        initialValues={{
          date: "",
          start_hour: "",
          comment: "",
        }}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          setFieldError,
          setFieldValue,
          setFieldTouched,
          values,
          isValid,
          touched,
          errors,
        }) => (
          <Form>
            <Modal.Item.Wrapper>
              {data?.show_time_warning && (
                <Alert.Low kind={ALERT.KIND.WARNING}>
                  {polyglot.t("job.litte_time_left_before_job")}
                </Alert.Low>
              )}
              <FormControl
                label={polyglot.t("new_date_time_change.modal.select_date")}
              >
                <DatePicker.Calendar
                  name="date"
                  format={DATE_FORMAT}
                  disabledDate={getDisabledDate}
                  minDate={getMinDate()}
                  onMonthChange={handleMonthChange}
                  onChange={(date) =>
                    handleDayClick(date, {
                      setFieldValue,
                      values,
                      setFieldError,
                    })
                  }
                />
              </FormControl>
              <FormControl
                label={polyglot.t("new_date_time_change.modal.select_hour")}
                hint={
                  isBooked &&
                  polyglot.t("new_date_time_change.select_hour_hint")
                }
              >
                <Select
                  name="start_hour"
                  onChange={({ value }) => {
                    setFieldTouched("start_hour", true);
                    setFieldValue("start_hour", value);
                  }}
                  error={!!touched.start_hour && errors.start_hour}
                  value={values.start_hour}
                  disabled={isLoading && isBooked}
                  options={getOptsHours(values.date)}
                  placeholder={polyglot.t(
                    "new_date_time_change.select_hour_placeholder"
                  )}
                />
              </FormControl>
              {isBooked && (
                <FormControl
                  hint={polyglot.t("common.remaining_characters_plural", {
                    count: (values?.comment?.length - MAX_COMMENT_LENGTH) * -1,
                  })}
                  label={polyglot.t(
                    "new_date_time_change.modal.custom_message"
                  )}
                >
                  <Field name="comment">
                    {({ field }) => (
                      <Textarea
                        maxLength={MAX_COMMENT_LENGTH}
                        {...field}
                        placeholder={polyglot.t("common.placeholder.textarea")}
                      />
                    )}
                  </Field>
                </FormControl>
              )}
              {query.isError && (
                <Alert.Low
                  kind={ALERT.KIND.DANGER}
                  css={`
                    margin-bottom: ${spacings.m};
                  `}
                >
                  {query.error?.response?.data?.error_message}
                </Alert.Low>
              )}
              {children && (
                <div
                  css={`
                    margin-bottom: ${spacings.m};
                  `}
                >
                  {children}
                </div>
              )}
              <Button.Large
                type="submit"
                block
                isLoading={submitIsLoading}
                disabled={!isValid}
              >
                {polyglot.t("common.confirm")}
              </Button.Large>
            </Modal.Item.Wrapper>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default EditDateTime;
