import polyglot from "../../../utils/polyglot";

export const CREDIT_CARD_NUMBER_MASK = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

export const PAYMENT_METHOD_ICON_WIDTH = 40;

export const CREDIT_CARD_EXP_DATE = [/[0-9]/, /[0-9]/, "/", /[0-9]/, /[0-9]/];
export const AMEX = "amex";
export const VISA_MASTERCARD = "visa_mastercard";
export const MAESTRO = "maestro";
export const VISA = "visa";
export const MASTERCARD = "mastercard";
export const PAYPAL = "paypal";
export const IBAN = "iban";
export const APPLE_PAY = "apple_pay";
export const GOOGLE_PAY = "google_pay";
export const IDEAL = "ideal";
export const TWINT = "twint";
export const BANCONTACT = "bancontact"
export const SUPPORTED_ERROR_CODES = ["105202", "105203", "105204"];

export const PAYMENT_METHODS = {
  [GOOGLE_PAY]: {
    label: polyglot.t("payment_methods.google_pay"),
    external: true,
  },
  [APPLE_PAY]: {
    label: polyglot.t("payment_methods.apple_pay"),
    external: true,
  },
  [VISA_MASTERCARD]: {
    securityCode: 3,
    label: polyglot.t("payment_methods.visa_mastercard"),
    external: false,
  },
  [VISA]: {
    securityCode: 3,
    label: polyglot.t("payment_methods.visa_mastercard"),
    external: false,
  },
  [MASTERCARD]: {
    securityCode: 3,
    label: polyglot.t("payment_methods.visa_mastercard"),
    external: false,
  },
  [MAESTRO]: {
    securityCode: 3,
    label: polyglot.t("payment_methods.maestro"),
    external: false,
  },
  [AMEX]: {
    securityCode: 4,
    label: polyglot.t("payment_methods.amex"),
    external: false,
  },
  [PAYPAL]: {
    label: polyglot.t("payment_methods.paypal"),
    description: polyglot.t("payment_methods.paypal_4x_description"),
    external: true,
  },
  [IDEAL]: {
    label: polyglot.t("payment_methods.ideal"),
    external: true,
  },
  [TWINT]: {
    label: polyglot.t("payment_methods.twint"),
    external: true,
  },
  [BANCONTACT]: {
    label: polyglot.t("payment_methods.bancontact"),
    external: true,
  },
};
