import React from "react";
import styled, { css } from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { Caption } from "./Text";
import { colors, borderWidth, radius, sizes } from "../assets/themes";
import { breakpointsEnhancer } from "./Styles/Helper";
import { BADGE } from "./Styles/variants";

const setPosition = ({ position }) => {
  if (position === BADGE.POSITION.TOP_RIGHT) {
    return css`
      top: 0;
      right: 0;
    `;
  }
  if (position === BADGE.POSITION.TOP_LEFT) {
    return css`
      top: 0;
      left: 0;
    `;
  }
  if (position === BADGE.POSITION.BOTTOM_RIGHT) {
    return css`
      bottom: 0;
      right: 0;
    `;
  }
  if (position === BADGE.POSITION.BOTTOM_LEFT) {
    return css`
      bottom: 0;
      left: 0;
    `;
  }
  return null;
};

const setAlign = ({ position }) => {
  if (position === BADGE.POSITION.TOP_RIGHT) {
    return { x: "40%", y: "-40%" };
  }
  if (position === BADGE.POSITION.TOP_LEFT) {
    return { x: "-40%", y: "40%" };
  }
  if (position === BADGE.POSITION.BOTTOM_RIGHT) {
    return { x: "-40%", y: "40%" };
  }
  if (position === BADGE.POSITION.BOTTOM_LEFT) {
    return { x: "40%", y: "-40%" };
  }
  return null;
};

const StyledWrapper = styled.div`
  display: inline-flex;
  position: relative;
  ${({ dot }) =>
    css`
      min-height: ${dot ? sizes.size8 : sizes.size16};
      min-width: ${dot ? sizes.size8 : sizes.size16};
    `}
`;

const StyledPositionBox = styled.div`
  ${({ dot }) => css`
    min-width: ${dot ? sizes.size8 : sizes.size16};
    width: ${dot && sizes.size8};
    height: ${dot ? sizes.size8 : sizes.size16};
  `}
  ${({ position, hasChildren }) =>
    position &&
    hasChildren &&
    css`
      ${setPosition({ position })};
      position: absolute;
      z-index: 1;
    `}
`;

const StyledBadge = styled.span`
  ${({ backgroundColor, color, outlineColor }) => css`
    ${breakpointsEnhancer("background-color", backgroundColor)};
    ${breakpointsEnhancer("color", color)};
    font-weight: var(--font-weight-bold);
    border-radius: ${radius.circle};
    display: flex;
    align-items: center;
    position: relative;
    justify-count: center;
    padding: 0 var(--spacing-xs);
    height: 100%;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      ${breakpointsEnhancer("color", outlineColor)};
      box-shadow: 0px 0px 0px ${borderWidth.m};
      border-radius: ${radius.circle};
    }
  `}
`;

const Badge = ({
  children,
  position = BADGE.POSITION.TOP_RIGHT,
  count,
  dot,
  showZero = false,
  outlineColor = colors.backgroundColor,
  backgroundColor = colors.danger,
  color = colors.onColor,
  className,
  ...rest
}) => (
  <StyledWrapper dot={dot} className={className}>
    <AnimatePresence initial={false}>
      {!((count === 0 || !count) && !dot && !showZero) && (
        <StyledPositionBox
          dot={dot}
          position={position}
          hasChildren={React.Children.count(children) > 0}
        >
          <motion.div
            initial={{
              scale: 0,
            }}
            style={{
              ...(!dot &&
                position &&
                React.Children.count(children) > 0 &&
                setAlign({ position })),
              height: "100%",
            }}
            transition={{ ease: "easeInOut", duration: 0.3 }}
            animate={{
              scale: 1,
            }}
          >
            <StyledBadge
              data-content="badge"
              outlineColor={outlineColor}
              backgroundColor={backgroundColor}
              dot={dot}
              color={color}
              {...rest}
            >
              <Caption strong style={{ marginLeft: "0.5px" }}>
                {count}
              </Caption>
            </StyledBadge>
          </motion.div>
        </StyledPositionBox>
      )}
    </AnimatePresence>

    {children && children}
  </StyledWrapper>
);

export default Badge;
