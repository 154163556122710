import { Block, List, Radio, Tag } from "@/components";
import { PAYMENT_METHOD_ICON_WIDTH } from "./constants";
import { getPaymentMethodBadge } from "./utils";
import { TAG } from "@/components/Styles/variants";
import polyglot from "@/utils/polyglot";
import { spacings } from "@/assets/themes";

const MethodItem = ({
  method,
  children,
  checked,
  onClick,
  RightComponent,
  current,
  ...rest
}) => (
  <List.Item
    onClick={onClick}
    RightComponent={() => (
      <Block display="flex" alignItems="center" gap={spacings.s}>
        {RightComponent && RightComponent()}
        {current && (
          <Tag.Small kind={TAG.KIND.WHITE}>
            {polyglot.t("common.default")}
          </Tag.Small>
        )}
        <Radio checked={checked} />
      </Block>
    )}
    LeftComponent={() => (
      <img
        src={getPaymentMethodBadge(method)}
        alt=""
        width={PAYMENT_METHOD_ICON_WIDTH}
      />
    )}
    {...rest}
  >
    {children}
  </List.Item>
);

export default MethodItem;
