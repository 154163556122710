import { spacings } from "@/assets/themes";
import { Block, Breadcrumb, Link } from "@/components";
import useJob from "@/modules/hooks/useJob";
import polyglot from "@/utils/polyglot";
import { DASHBOARD_PATH } from "../dashboard/config/routes";
import Assistant from "./Assistant";
import CustomContainer from "./CustomContainer";
import Job from "./Job";

const JobMain = () => {
  const { isAssistant, isPoster, data } = useJob();
  return (
    <div>
      <Block paddingY={spacings.m} display={{ xs: "none", md: "block" }}>
        {isPoster && (
          <CustomContainer>
            <Breadcrumb>
              <Link href={DASHBOARD_PATH}>{polyglot.t("routes.jobs")}</Link>
              <span>{data.title}</span>
            </Breadcrumb>
          </CustomContainer>
        )}
      </Block>
      {isAssistant ? <Assistant /> : <Job />}
    </div>
  );
};

export default JobMain;
