import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import Logo from "images/brand/yoojo-logo-icon@white.svg";
import {
  borderWidth,
  colors,
  radius,
  shadows,
  sizes,
  spacings,
} from "../../assets/themes";
import {
  Avatar,
  Badge,
  Block,
  Body16,
  Caption,
  InfiniteScrollEnhancer,
  List,
} from "../../components";
import { LIST } from "../../components/Styles/variants";
import { useRefetchOnHistoryEvent } from "../../modules/hooks";
import { useGetNotifications } from "../../modules/routes/dashboard-routes";
import { DASHBOARD_SMALL_CONTAINER } from "../../utils";
import polyglot from "../../utils/polyglot";
import PageTitle from "./PageTitle";
import { NOTIFICATIONS_PATH } from "./config/routes";
import NotificationsSkeleton from "./skeletons/NotificationsSkeleton";

const Item = ({
  id,
  title,
  read,
  created_at,
  viewed,
  setSeen,
  image,
  user,
  redirection,
}) => (
  <>
    <List.Item
      shape={LIST.SHAPE.ROUND}
      css={`
        padding-top: ${spacings.s};
        padding-bottom: ${spacings.s};
        margin-bottom: ${spacings.m};
        box-shadow: ${shadows.xs};
        border: solid ${borderWidth.s} ${colors.border};
      `}
      size={LIST.SIZE.LARGE}
      divider={false}
      withGutters
      onClick={() => setSeen(id)}
      href={`${NOTIFICATIONS_PATH}/${id}`}
      target={["webview", "external_webview"].includes(redirection) ? "_blank" : undefined}
      LeftComponent={() => (
        <Block display="flex" alignItems="center">
          {image || user?.avatar ? (
            <Avatar
              src={image || user?.avatar}
              name={user?.first_name}
              size={sizes.size48}
            />
          ) : (
            <Block
              backgroundColor={colors.primary}
              width={sizes.size48}
              height={sizes.size48}
              padding={spacings.s}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius={radius.circle}
            >
              <img
                src={Logo}
                alt={polyglot.t("common.brand")}
                css={`
                  width: 100%;
                  height: auto;
                `}
              />
            </Block>
          )}
        </Block>
      )}
    >
      <Block display="flex" alignItems="center">
        <Caption color={colors.muted}>{created_at}</Caption>
        {!viewed && (
          <Block marginLeft={spacings.xs} display="inherit">
            <Badge dot backgroundColor={colors.primary} />
          </Block>
        )}
      </Block>
      <Body16 strong={!read} color={colors.body}>
        {title}
      </Body16>
    </List.Item>
  </>
);

const areEqual = (p, n) => p.read === n.read;
const ItemMemo = React.memo(Item, areEqual);

const Notifications = ({ config }) => {
  const getNotifications = useGetNotifications();
  const queryClient = useQueryClient();
  useRefetchOnHistoryEvent(getNotifications);
  const setSeen = (id) => {
    /* eslint no-param-reassign: ["error", { "props": false }] */
    queryClient.setQueryData([config.path], (oldData) => {
      const n = oldData;
      n?.pages?.forEach((page) => {
        page.data.notifications?.forEach((child) => {
          if (Number(child.id) === Number(id)) {
            child.read = true;
          }
        });
      });
      return n;
    });
  };

  return (
    <Block maxWidth={DASHBOARD_SMALL_CONTAINER} width="100%">
      <PageTitle>{config.title}</PageTitle>
      <Block marginTop={spacings.m}>
        <InfiniteScrollEnhancer
          query={getNotifications}
          unionBy="notifications"
        >
          {({ data, isLoading }) =>
            !isLoading ? (
              <>
                {data.notifications.length > 0 ? (
                  <Block>
                    {data.notifications.map((notification) => (
                      <ItemMemo
                        setSeen={setSeen}
                        key={`notification-${notification.id}`}
                        {...notification}
                      />
                    ))}
                  </Block>
                ) : (
                  <Body16 color={colors.muted}>
                    {polyglot.t("notifications.no_notification")}
                  </Body16>
                )}
              </>
            ) : (
              <NotificationsSkeleton />
            )
          }
        </InfiniteScrollEnhancer>
      </Block>
    </Block>
  );
};

export default Notifications;
