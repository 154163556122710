import { useFormikContext } from "formik";
import moment from "moment";
import { Body14, Body16, List } from "@/components";
import { formatCardNumber } from "@/utils";
import polyglot from "@/utils/polyglot";
import MethodItem from "./MethodItem";
import { getCreditCardTypeByName, getPaymentMethodConfig } from "./utils";
import { colors } from "@/assets/themes";

const MethodsOptions = ({
  cards,
  selectedCard,
  methods,
  onChange,
  disabled,
  withGutters,
  isExternal = false,
  ...rest
}) => {
  const { values } = useFormikContext();
  const value = {
    payment_method: values?.payment_method,
    mangopay_card_id: values?.mangopay_card_id,
  };

  const handleSelect = (item) => {
    onChange({
      mangopay_card_id: item?.id || null,
      payment_method: getCreditCardTypeByName(item?.payment_method),
    });
  };

  const isSelected = (item) => {
    // CREDIT CARD
    if (value?.mangopay_card_id && item.id) {
      return value?.mangopay_card_id === item.id;
    }
    // NEW CREDIT CARD
    if (item.payment_method === "") {
      return !value?.mangopay_card_id && !value?.payment_method;
    }
    // PAYMENT METHOD
    if (getPaymentMethodConfig(item.payment_method)?.external) {
      return value?.payment_method === item.payment_method;
    }
    // SELECTED CREDIT CARD
    return selectedCard?.id === item.id;
  };

  return (
    <List.Group>
      {!isExternal && (
        <>
          {cards
            ?.filter(
              (card) =>
                !getPaymentMethodConfig(getCreditCardTypeByName(card.kind))
                  ?.external &&
                methods.includes(getCreditCardTypeByName(card.kind))
            )
            .map((card, i) => (
              <MethodItem
                key={card.id}
                method={card.kind}
                checked={isSelected({ ...card, payment_method: card.kind })}
                onClick={() =>
                  handleSelect({ ...card, payment_method: card.kind })
                }
                current={card.current}
                disabled={disabled}
                withGutters={withGutters}
                {...rest}
              >
                <Body16 strong>{formatCardNumber(card.alias)}</Body16>
                <Body14 color={colors.muted}>
                  {moment(card.expiration_date).format("MM/YY")}
                </Body14>
              </MethodItem>
            ))}
          <MethodItem
            key="placeholder"
            method=""
            checked={isSelected({ payment_method: "" })}
            onClick={() => handleSelect({ payment_method: "", id: null })}
            disabled={disabled}
            withGutters={withGutters}
            divider={isExternal}
            {...rest}
          >
            <Body16>{polyglot.t("payment_method.add_card")}</Body16>
          </MethodItem>
        </>
      )}
      {isExternal &&
        methods
          .filter((method) => getPaymentMethodConfig(method)?.external)
          .map((method) => (
            <MethodItem
              key={method}
              method={method}
              checked={isSelected({ payment_method: method })}
              onClick={() => handleSelect({ payment_method: method })}
              disabled={disabled}
              withGutters={withGutters}
              {...rest}
            >
              <Body16>{polyglot.t(`payment_methods.${method}`)}</Body16>
            </MethodItem>
          ))}
    </List.Group>
  );
};

export default MethodsOptions;
