import { MemoryRouter, Route } from "react-router-dom/cjs/react-router-dom.min";
import { Body16, Icon, List } from "@/components";
import useJob from "@/modules/hooks/useJob";
import CloseJobModal from "@/pages/dashboard/job/CloseJobModal";
import MultipleCloseJobModal from "@/pages/dashboard/job/MultipleCloseJobModal";
import EditJobModal from "@/pages/editJob/EditJobModal";
import {
  CLOSE_MULTIPLE_PATH,
  CLOSE_PATH,
  EDIT_INFORMATIONS_PATH,
  getCancelOfferUrl,
  getCloseJobRedirection,
  getSelectedOffers,
  JOB_DATETIME_EDIT_PATH,
} from "../utils";
import polyglot from "@/utils/polyglot";
import { colors, spacings } from "@/assets/themes";
import { CardBase } from "@/components/Styles/Base";
import { useBreakpoints } from "@/modules/hooks";

const ManageJob = () => {
  const { data } = useJob();
  const breakpoints = useBreakpoints();
  const isCancelable = data.accepted_offers.some((offer) => offer.cancelable);

  const MANAGE_JOB = [
    ...(data.can_edit_datetime
      ? [
          {
            title: "Demander un changement de date",
            icon: "calendar-edit",
            to: JOB_DATETIME_EDIT_PATH,
          },
        ]
      : []),
    ...(isCancelable
      ? getSelectedOffers(data.accepted_offers).map((offer) => ({
          title: polyglot.t("jobs.change_or_cancel_first_name", {
            first_name: offer.jobber.first_name,
          }),
          icon: "user-times",
          href: getCancelOfferUrl(offer.id),
        }))
      : []),
  ];

  return (
    <MemoryRouter>
      <Route
        render={({ history }) =>
          MANAGE_JOB.length > 0 ? (
            <>
              <List.Header>Gérer ma demande</List.Header>
              <CardBase
                flat
                border={breakpoints.get({ xs: true, md: false })}
                css={`
                  /* border instead of padding to have visually better List.Item hover */
                  ${breakpoints.get({
                    xs: `padding-top: ${spacings.xs}; padding-bottom: ${spacings.xs};`,
                    md: `border: solid ${spacings.xs} ${colors.background};`,
                  })};
                  overflow: hidden;
                `}
              >
                <List.Group>
                  {MANAGE_JOB.map((item) => (
                    <List.Item
                      withGutters
                      key={item.title}
                      href={item.href}
                      onClick={() => {
                        if (item.to) {
                          history.replace(item.to);
                        }
                        if (item.onClick) item.onClick();
                      }}
                      LeftComponent={() => <Icon.Large name={item.icon} />}
                    >
                      <Body16>{item.title}</Body16>
                    </List.Item>
                  ))}
                </List.Group>
              </CardBase>
              <Route
                path={[
                  `${EDIT_INFORMATIONS_PATH}/:field`,
                  EDIT_INFORMATIONS_PATH,
                ]}
              >
                <EditJobModal
                  isOpen
                  onClose={() => history.replace("/")}
                  canBack={false}
                />
              </Route>
              <Route path={CLOSE_PATH}>
                <CloseJobModal isOpen onClose={() => history.replace("/")} />
              </Route>
              <Route path={CLOSE_MULTIPLE_PATH}>
                <MultipleCloseJobModal
                  isOpen
                  onClose={() => history.replace("/")}
                />
              </Route>
            </>
          ) : null
        }
      />
    </MemoryRouter>
  );
};

export default ManageJob;
