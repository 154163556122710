import mangoPay from "mangopay-cardregistration-js-kit";
import axios from "axios";
import {
  sendData,
  mangoPayError,
} from "../../modules/actions/global/form-actions";
import polyglot from "../polyglot";

const initMangopay = (config) => {
  mangoPay.cardRegistration.baseURL = config.apiUrl;
  mangoPay.cardRegistration.clientId = config.clientId;
};

const cardTokenizeSuccess = (
  res,
  filteredAttrs,
  config,
  dispatch,
  httpMethod
) => {
  sendData(res, filteredAttrs, config, dispatch, httpMethod);
};

const cardTokenizeFailed = (error, dispatch) => {
  const dataError = {
    errorMessage: polyglot.has(`common.errors.card.error_${error.ResultCode}`)
      ? polyglot.t(`common.errors.card.error_${error.ResultCode}`)
      : polyglot.t(`common.errors.card.error_invalid`),
  };
  if (error.Id) {
    axios
      .get("/mangopay_cards/new", {})
      .then((response) => {
        dataError.mangopayConfig = response.data.data.mangopayConfig;
        mangoPayError(dataError, dispatch);
      });
  } else {
    mangoPayError(dataError, dispatch);
  }
};

export const cardTokenize = (data, config, dispatch, httpMethod) => {
  initMangopay(config);
  mangoPay.cardRegistration.init({
    cardRegistrationURL: config.cardRegistrationUrl,
    preregistrationData: config.preregistrationData,
    accessKey: config.accessKey,
    Id: config.registrationId,
  });
  mangoPay.cardRegistration.registerCard(
    data.creditCard,
    (res) => cardTokenizeSuccess(res, data.attrs, config, dispatch, httpMethod),
    (error) => cardTokenizeFailed(error, dispatch)
  );
};
