import { Button, H2, Modal } from "@/components";
import { BUTTON } from "@/components/Styles/variants";
import polyglot from "@/utils/polyglot";
import PaymentMethodItem from "./PaymentMethodItem";

const PaymentMethodDetailsModal = ({
  card,
  isOpen,
  onClose,
  onRemove,
  onDefault,
  isDefaultLoading,
  isRemoveLoading,
}) => (
  <Modal.Small isOpen={isOpen} onClose={onClose}>
    <Modal.Item.Header onClose={onClose}>
      <Modal.Item.Title as={H2}>
        {polyglot.t("manage_payment_method.details")}
      </Modal.Item.Title>
    </Modal.Item.Header>
    <Modal.Item.Wrapper>
      <PaymentMethodItem card={card} />
    </Modal.Item.Wrapper>
    <Modal.Item.Footer column>
      <Button.Medium
        block
        kind={BUTTON.KIND.TERTIARY}
        onClick={onDefault}
        isLoading={isDefaultLoading}
        disabled={card.current}
      >
        {polyglot.t("common.default")}
      </Button.Medium>
      <Button.Medium
        block
        kind={BUTTON.KIND.SECONDARY}
        accentColor={BUTTON.ACCENT_COLOR.DANGER}
        onClick={onRemove}
        isLoading={isRemoveLoading}
      >
        {polyglot.t("common.remove")}
      </Button.Medium>
    </Modal.Item.Footer>
  </Modal.Small>
);

export default PaymentMethodDetailsModal;
