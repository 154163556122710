import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { spacings } from "../../../../assets/themes";
import { Block } from "../../../../components";
import { useCategoryForm } from "../../../../modules/hooks";
import Recommendation from "../../common/Recommendation";
import RecommendationLoading from "../../common/RecommendationLoading";
import { useGetDistance } from "../../useGetDistance";
import AnimatedTruck from "../../../../assets/lottie/truck-loading.lottie";
import useCategoryValue from "../../useCategoryValue";
import polyglot from "../../../../utils/polyglot";

const RecommendationWrapper = () => {
  const [animationComplete, setAnimationComplete] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const { flowControl, nhConfig } = useCategoryForm();
  const fetchEnabled = !!values.destination_address;
  const { getNbJobbers } = useCategoryValue();

  const { isLoading, isSuccess, data } = useGetDistance(values, {
    enabled: fetchEnabled,
  });

  const [showLoading, setShowLoading] = useState(
    fetchEnabled ? isLoading : false
  );

  const handleAnimationComplete = () => {
    setAnimationComplete(true);
  };

  useEffect(() => {
    flowControl.setNextIsLoading(showLoading);
    return () => {
      flowControl.setNextIsLoading(false);
    };
  }, [showLoading]);

  useEffect(() => {
    if (isSuccess && animationComplete) {
      setShowLoading(false);
      setFieldValue("nh_specific2[1]", data.distance_in_meters, false);
      setFieldValue("nh_specific2[2]", data.duration_in_seconds, false);
    }
  }, [isSuccess, animationComplete]);

  const getTruckBoolean = (value) => {
    if (value === 0) return false;
    if (value === 1) return true;
    return null;
  };

  return (
    <>
      {showLoading ? (
        <Block
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginY={spacings.xxl}
        >
          <RecommendationLoading
            animationData={AnimatedTruck}
            onAnimationComplete={handleAnimationComplete}
            title={polyglot.t("categories.calculate_ideal_service_duration")}
            phrases={[
              polyglot.t("categories.calculate_distance"),
              polyglot.t("categories.calculate_required_time"),
            ]}
          />
        </Block>
      ) : (
        <Recommendation
          items={[
            ...(values.destination_address
              ? {
                  icon: "surface-small",
                  label: polyglot.t("categories.number_of_device_to_move"),
                  value: nhConfig.nh_specific.options.find(
                    (o) => o.value === values.nh_specific
                  ).label,
                }
              : []),
          ]}
          title={polyglot.t("categories.job_recommendation_plural", {
            count: getNbJobbers(),
            nb_hours: polyglot.toDuration(values.nb_hours_calculated, true),
          })}
          distanceInMeters={
            values.destination_address ? data?.distance_in_meters : null
          }
          truck={
            values.destination_address
              ? getTruckBoolean(values.nh_specific3)
              : null
          }
        />
      )}
    </>
  );
};
export default RecommendationWrapper;
